import actions from "./actions";

const initState = {
  giftcards: [],
  templates: [],
  hasMoreGiftcards: true
};

export default function giftcardReducer(state = initState, action) {

  switch (action.type) {
    case actions.GIFTCARDS_REQUEST:
      var requestState = {
        ...state,
        hasMoreGiftcards: true,
        isLoading: true
      };

      return requestState;

    case actions.GIFTCARDS_SUCCESS:
      return {
        ...state,
        hasMoreGiftcards: action.giftcards.length > 0,
        isLoading: false,
        giftcards:
          state.giftcards && action.start > 0
            ? state.giftcards.concat(action.giftcards)
            : action.giftcards
      };

    case actions.GIFTCARDS_TEMPLATES_SUCCESS:
      return { ...state, templates: action.templates };

    case actions.GIFTCARDS_CLEAR:
      return { ...state, giftcards: [] };

    case actions.GIFTCARDS_ERROR:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}
