import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../settings';
import { getTokenHeader } from '../../helpers/utility';

export function* getCards() {
  yield takeEvery(actions.CARDS_REQUEST, function*() {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/cards', {
        headers: getTokenHeader()
      });

      if (response.status !== 200) {
        yield put({ type: actions.OVERVIEW_ERROR });
        return;
      }

      const responseBody = yield response.json();
      yield put({
        type: actions.CARDS_SUCCESS,
        cards: responseBody
      });

    } 
    catch (e) {
        yield put({ type: actions.OVERVIEW_ERROR });
        return;
    }
  });
}

export function* getChartTopRestaurantsClosedBills() {
  yield takeEvery(actions.CHART_TOP_RESTAURANTS_CLOSED_BILLS_REQUEST, function*({perspective, start, end}) {
    try {
      const url = getChartEndpointUrl('top_restaurants_closed_bills', perspective, start, end);
      const response = yield call(fetch, url, { headers: getTokenHeader() });
      yield handleChartResponse(response, actions.CHART_TOP_RESTAURANTS_CLOSED_BILLS_SUCCESS);
    } 
    catch (e) {
        yield put({ type: actions.OVERVIEW_ERROR });
        return;
    }
  });
}

export function* getChartClosedBills() {
  yield takeEvery(actions.CHART_CLOSED_BILLS_REQUEST, function*({perspective, start, end}) {
    try {
      const url = getChartEndpointUrl('closed_bills', perspective, start, end);
      const response = yield call(fetch, url, { headers: getTokenHeader() });
      yield handleChartResponse(response, actions.CHART_CLOSED_BILLS_SUCCESS);
    } 
    catch (e) {
        yield put({ type: actions.OVERVIEW_ERROR });
        return;
    }
  });
}

export function* getChartBillsWithCredits() {
  yield takeEvery(actions.CHART_BILLS_WITH_CREDITS_REQUEST, function*({perspective, start, end}) {
    try {
      const url = getChartEndpointUrl('bills_with_credits', perspective, start, end);
      const response = yield call(fetch, url, { headers: getTokenHeader() });
      yield handleChartResponse(response, actions.CHART_BILLS_WITH_CREDITS_SUCCESS);
    } 
    catch (e) {
        yield put({ type: actions.OVERVIEW_ERROR });
        return;
    }
  });
}

export function* getChartUsedPaymentMethods() {
  yield takeEvery(actions.CHART_USED_PAYMENT_METHODS_REQUEST, function*({perspective, start, end}) {
    try {
      const url = getChartEndpointUrl('used_payment_options', perspective, start, end);
      const response = yield call(fetch, url, { headers: getTokenHeader() });
      yield handleChartResponse(response, actions.CHART_USED_PAYMENT_METHODS_SUCCESS);
    } 
    catch (e) {
        yield put({ type: actions.OVERVIEW_ERROR });
        return;
    }
  });
}

export function* getChartTurnoverPaymentMethods() {
  yield takeEvery(actions.CHART_TURNOVER_PAYMENT_METHODS_REQUEST, function*({perspective, start, end}) {
    try {
      const url = getChartEndpointUrl('turnover_by_payment_method', perspective, start, end);
      const response = yield call(fetch, url, { headers: getTokenHeader() });
      yield handleChartResponse(response, actions.CHART_TURNOVER_PAYMENT_METHODS_SUCCESS);
    } 
    catch (e) {
        yield put({ type: actions.OVERVIEW_ERROR });
        return;
    }
  });
}

export function* getChartCreditTurnover() {
  yield takeEvery(actions.CHART_CREDIT_TURNOVER_REQUEST, function*({perspective, start, end}) {
    try {
      const url = getChartEndpointUrl('turnover_credits', perspective, start, end);
      const response = yield call(fetch, url, { headers: getTokenHeader() });
      yield handleChartResponse(response, actions.CHART_CREDIT_TURNOVER_SUCCESS);
    } 
    catch (e) {
        yield put({ type: actions.OVERVIEW_ERROR });
        return;
    }
  });
}

export function* getChartNewUsers() {
  yield takeEvery(actions.CHART_NEW_USERS_REQUEST, function*({perspective, start, end}) {
    try {
      const url = getChartEndpointUrl('new_users', perspective, start, end);
      const response = yield call(fetch, url, { headers: getTokenHeader() });
      yield handleChartResponse(response, actions.CHART_NEW_USERS_SUCCESS);
    } 
    catch (e) {
        yield put({ type: actions.OVERVIEW_ERROR });
        return;
    }
  });
}

export function* getChartBooking() {
  yield takeEvery(actions.CHART_BOOKINGS_REQUEST, function*({perspective, start, end}) {
    try {
      const url = getChartEndpointUrl('bookings', perspective, start, end);
      const response = yield call(fetch, url, { headers: getTokenHeader() });
      yield handleChartResponse(response, actions.CHART_BOOKINGS_SUCCESS);
    } 
    catch (e) {
        yield put({ type: actions.OVERVIEW_ERROR });
        return;
    }
  });
}

export function* getChartTurnoverAndRevenue() {
  yield takeEvery(actions.CHART_TURNOVER_AND_REVENUE_REQUEST, function*({perspective, start, end}) {
    try {
      const url = getChartEndpointUrl('turnover_revenue', perspective, start, end);
      const response = yield call(fetch, url, { headers: getTokenHeader() });
      yield handleChartResponse(response, actions.CHART_TURNOVER_AND_REVENUE_SUCCESS);
    } 
    catch (e) {
        yield put({ type: actions.OVERVIEW_ERROR });
        return;
    }
  });
}

function getChartEndpointUrl(id, perspective, start, end) {
  return settings.apiUrl + '/dashboard/charts?' +
  'id=' + id +
  '&perspective=' + perspective +
  '&start=' + encodeURIComponent(start.format()) +
  '&end=' + encodeURIComponent(end.format());
}

function* handleChartResponse (response, successType, failType) {
  if (response.status !== 200) {
    yield put({ type: failType || actions.OVERVIEW_ERROR });
    return;
  }

  const responseBody = yield response.json();
  yield put({
    type: successType,
    chart: responseBody
  });
}

export default function* rootSaga() {
  yield all([
    fork(getCards),
    fork(getChartTopRestaurantsClosedBills),
    fork(getChartClosedBills),
    fork(getChartBillsWithCredits),
    fork(getChartUsedPaymentMethods),
    fork(getChartTurnoverPaymentMethods),
    fork(getChartCreditTurnover),
    fork(getChartNewUsers),
    fork(getChartBooking),
    fork(getChartTurnoverAndRevenue),
  ]);
}
