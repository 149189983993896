const actions = {
  BILLS_REQUEST: 'BILLS_REQUEST',
  BILLS_SUCCESS: 'BILLS_SUCCESS',
  BILL_DETAILS_REQUEST: 'BILL_DETAILS_REQUEST',
  BILL_DETAILS_SUCCESS: 'BILL_DETAILS_SUCCESS',
  BILL_MANAGE_REQUEST: 'BILL_MANAGE_REQUEST',
  BILL_MANAGE_SUCCESS: 'BILL_MANAGE_SUCCESS',
  BILL_DELETE_REQUEST: 'BILL_DELETE_REQUEST',
  BILL_DELETE_SUCCESS: 'BILL_DELETE_SUCCESS',
  BILL_RECOVERY_DETAILS_REQUEST: 'BILL_RECOVERY_DETAILS_REQUEST',
  BILL_RECOVERY_DETAILS_SUCCESS: 'BILL_RECOVERY_DETAILS_SUCCESS',
  BILL_RECOVERY_SEARCH_REQUEST: 'BILL_RECOVERY_SEARCH_REQUEST',
  BILL_RECOVERY_SEARCH_SUCCESS: 'BILL_RECOVERY_SEARCH_SUCCESS',
  BILL_RECOVERY_PROCESS_REQUEST: 'BILL_RECOVERY_PROCESS_REQUEST',
  BILL_RECOVERY_PROCESS_SUCCESS: 'BILL_RECOVERY_PROCESS_SUCCESS',
  BILL_RECOVERY_PROCESS_ERROR: 'BILL_RECOVERY_PROCESS_ERROR',
  BILL_RECEIPT_DOWNLOAD_REQUEST: 'BILL_RECEIPT_DOWNLOAD_REQUEST',
  BILL_RECEIPT_DOWNLOAD_SUCCESS: 'BILL_RECEIPT_DOWNLOAD_SUCCESS',
  BILL_PAY_REQUEST: 'BILL_PAY_REQUEST',
  BILL_PAY_SUCCESS: 'BILL_PAY_SUCCESS',
  BILLS_ERROR: 'BILLS_ERROR',
  BILLS_CLEAR: 'BILL_CLEAR',
  getBills: (start, count, filter, search, startTime, endTime) => ({
    type: actions.BILLS_REQUEST,
    start,
    count,
    filter,
    search,
    startTime,
    endTime
  }),
  getBillDetails: (id, callback) => ({
    type: actions.BILL_DETAILS_REQUEST,
    id,
    callback
  }),
  deleteBill: (id) => ({
    type: actions.BILL_DELETE_REQUEST,
    id
  }),
  manageBill: (id, data, intl) => ({
    type: actions.BILL_MANAGE_REQUEST,
    id,
    data,
    intl
  }),
  clearBills: () => ({
    type: actions.BILLS_CLEAR
  }),
  getRecoveryBillDetails: (billId) => ({
    type: actions.BILL_RECOVERY_DETAILS_REQUEST,
    billId
  }),
  searchRecoveryBillOrders: (tableNumber, amount, startTime, endTime) => ({
    type: actions.BILL_RECOVERY_SEARCH_REQUEST,
    tableNumber,
    amount,
    startTime,
    endTime
  }),
  recoverBill: (billId, orderId) => ({
    type: actions.BILL_RECOVERY_PROCESS_REQUEST,
    billId,
    orderId
  }),
  getBillReceipt: (billId) => ({
    type: actions.BILL_RECEIPT_DOWNLOAD_REQUEST,
    billId
  }),
  payBill: (billId, intl) => ({
    type: actions.BILL_PAY_REQUEST,
    billId,
    intl
  }),
};
export default actions;
