import themedefault from './themedefault';
import theme2 from './theme2';
import thememaitres from './thememaitres';

const themes = {
  themedefault,
  theme2,
  thememaitres
};
export default themes;
