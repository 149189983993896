const actions = {
  BANNERS_REQUEST: 'BANNERS_REQUEST',
  BANNERS_SUCCESS: 'BANNERS_SUCCESS',
  BANNERS_DETAILS_REQUEST: 'BANNERS_DETAILS_REQUEST',
  BANNERS_DETAILS_SUCCESS: 'BANNERS_DETAILS_SUCCESS',
  BANNERS_CREATE_REQUEST: 'BANNERS_CREATE_REQUEST',
  BANNERS_CREATE_SUCCESS: 'BANNERS_CREATE_SUCCESS',
  BANNERS_UPDATE_REQUEST: 'BANNERS_UPDATE_REQUEST',
  BANNERS_UPDATE_SUCCESS: 'BANNERS_UPDATE_SUCCESS',
  BANNERS_DELETE_REQUEST: 'BANNERS_DELETE_REQUEST',
  BANNERS_DELETE_SUCCESS: 'BANNERS_DELETE_SUCCESS',
  BANNERS_PREVIEW_REQUEST: 'BANNERS_PREVIEW_REQUEST',
  BANNERS_PREVIEW_SUCCESS: 'BANNERS_PREVIEW_SUCCESS',
  BANNERS_ERROR: 'BANNERS_ERROR',
  BANNERS_CLEAR: 'BANNERS_CLEAR',
  getBanners: () => ({
    type: actions.BANNERS_REQUEST
  }),
  getBannerDetails: (id) => ({
    type: actions.BANNERS_DETAILS_REQUEST,
    id
  }),
  createBanner: (data, imageFile, intl) => ({
    type: actions.BANNERS_CREATE_REQUEST,
    data,
    imageFile,
    intl
  }),
  updateBanner: (oldData, newData, imageFile, intl) => ({
    type: actions.BANNERS_UPDATE_REQUEST,
    oldData,
    newData,
    imageFile, 
    intl
  }),
  deleteBanner: (id, intl) => ({
    type: actions.BANNERS_DELETE_REQUEST,
    id,
    intl
  }),
  getBannersPreview: () => ({
    type: actions.BANNERS_PREVIEW_REQUEST
  }),
  clearBanners: () => ({
    type: actions.BANNERS_CLEAR
  }),
};
export default actions;
