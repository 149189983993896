import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../settings';
import appMessageConstants from '../../containers/AppMessages/constants';
import { getTokenHeader, showNotificationSavedSuccess, showNotificationSavedFailed, uploadFile } from '../../helpers/utility';

export function* getAppMessages() {
  yield takeEvery(actions.APP_MESSAGES_REQUEST, function*({start, count, search}) {
    try {

      let url = settings.apiUrl + '/dashboard/messages?' +
      'start=' + start +
      '&count=' + count;
      if (search) {
        url += '&q=' + encodeURIComponent(search);
      }

      const response = yield call(fetch, url, {
        headers: getTokenHeader()
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('getAppMessages error'));
      }

      const appMessages = yield response.json();
      yield put({
        type: actions.APP_MESSAGES_SUCCESS,
        appMessages,
        search,
        start
      });
    } 
    catch (e) {
      yield put({ type: actions.APP_MESSAGES_ERROR });
      return;
    }
  });
}

export function* getAppMessageDetails() {
  yield takeEvery(actions.APP_MESSAGES_DETAILS_REQUEST, function*({id}) {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/messages/' + id, {
        headers: getTokenHeader()
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('getAppMessageDetails error'));
      }

      const appMessage = yield response.json();
      setAppMessageActionVariables(appMessage);
      
      yield put({
        type: actions.APP_MESSAGES_DETAILS_SUCCESS,
        appMessage
      });
    } 
    catch (e) {
      yield put({ type: actions.APP_MESSAGES_ERROR });
      return;
    }
  });
}

export function* createAppMessage() {
  yield takeEvery(actions.APP_MESSAGES_CREATE_REQUEST, function*({data, imageFile, intl}) {
    try {
      let body = getAppMessageRequestBody(data);

      if (imageFile) {
        let uploadMediaResponse = yield call(uploadFile, imageFile);
        body.image_id = uploadMediaResponse.id;
      }

      const response = yield call(fetch, settings.apiUrl + '/dashboard/messages', {
        method: 'POST',
        headers: getTokenHeader(),
        body: JSON.stringify(body)
      });

      if (response.status !== 200) {
        throw Object.assign(new Error('createAppMessage error'));
      }
  
      const appMessage = yield response.json();
      yield put({
        type: actions.APP_MESSAGES_CREATE_SUCCESS,
        appMessage
      });

      showNotificationSavedSuccess(intl);
    } 
    catch (e) {
      showNotificationSavedFailed(intl);
      yield put({ type: actions.APP_MESSAGES_ERROR });
      return;
    }
  });
}

export function* updateAppMessage() {
  yield takeEvery(actions.APP_MESSAGES_UPDATE_REQUEST, function*({oldData, newData, imageFile, intl}) {
    try {
      let body = getAppMessageRequestBody(newData);
      if (imageFile) {
        let uploadMediaResponse = yield call(uploadFile, imageFile);
        body.image_id = uploadMediaResponse.id;
      }
      else {
        body.image_id = oldData.image_id;
      }

      const response = yield call(fetch, settings.apiUrl + '/dashboard/messages/' + oldData.id, {
        method: 'PATCH',
        headers: getTokenHeader(),
        body: JSON.stringify(body)
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('updateAppMessage error'));
      }
  
      const appMessage = yield response.json();
      setAppMessageActionVariables(appMessage);

      yield put({
        type: actions.APP_MESSAGES_UPDATE_SUCCESS,
        appMessage
      });

      showNotificationSavedSuccess(intl);
    } 
    catch (e) {
      showNotificationSavedFailed(intl);
      yield put({ type: actions.APP_MESSAGES_ERROR });
      return;
    }
  });
}

export function* deleteAppMessage() {
  yield takeEvery(actions.APP_MESSAGES_DELETE_REQUEST, function*({id, intl}) {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/messages/' + id, {
        method: 'DELETE',
        headers: getTokenHeader()
      });

      if (response.status !== 204) {
        throw Object.assign(new Error('deleteAppMessage error'));
      }

      yield put({
        type: actions.APP_MESSAGES_DELETE_SUCCESS,
        deletedAppMessageId: id
      });
    } 
    catch (e) {
      showNotificationSavedFailed(intl);
      yield put({ type: actions.APP_MESSAGES_ERROR });
      return;
    }
  });
}

export function* getAppMessageFilters() {
  yield takeEvery(actions.APP_MESSAGES_FILTER_REQUEST, function*({start, count, search}) {
    try {

      const response = yield call(fetch, settings.apiUrl + '/dashboard/message/filters/available', {
        headers: getTokenHeader()
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('getAppMessageFilters error'));
      }

      const appMessageFilters = yield response.json();
      yield put({
        type: actions.APP_MESSAGES_FILTER_SUCCESS,
        appMessageFilters
      });
    } 
    catch (e) {
      yield put({ type: actions.APP_MESSAGES_ERROR });
      return;
    }
  });
}

function getAppMessageRequestBody(data) {
  let body = {
    type: data.type,
    titles: data.titles,
    messages_short: data.messages_short,
    messages: data.messages ? data.messages : null,
    htmls: data.htmls ? data.htmls : null,
    action: data.action,
    publish_at: data.publish_at.clone().utc().format()
  }

  if (data.date_end) {
    body.date_end = data.date_end.clone().utc().format();
  }

  if (body.action) {
    if (data.action_restaurant) {
      body.action += '/' + data.action_restaurant;
    }
    else if (data.action_promo) {
      body.action += '/' + data.action_promo;
    }
  }

  if (data.filters) {
    var filters = [];
    data.filters.forEach(element => {
      filters.push({ type: element});
    });
    body.filters = filters;
  }

  return body;
}

function setAppMessageActionVariables(appMessage) {
  if (appMessage.action) {
    let actionValues = appMessage.action.split('/');
    if (actionValues.length > 0) {
      appMessage.action = actionValues[0];
      if (actionValues.length > 1) {
        if (appMessage.action === appMessageConstants.ACTION_RESTAURANT 
          || appMessage.action === appMessageConstants.ACTION_CHAT 
          || appMessage.action === appMessageConstants.ACTION_BOOKING) {
            appMessage.action_restaurant = actionValues[1];
        }
        else if (appMessage.action === appMessageConstants.ACTION_PROMO) {
          appMessage.action_promo = actionValues[1];
        }
      }
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(getAppMessages),
    fork(getAppMessageDetails),
    fork(createAppMessage),
    fork(updateAppMessage),
    fork(deleteAppMessage),
    fork(getAppMessageFilters)
  ]);
}
