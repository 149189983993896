import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  {
    path: 'overview-today',
    component: asyncComponent(() => import('../Overview/OverviewToday')),
  },
  {
    path: 'overview-bills',
    component: asyncComponent(() => import('../Overview/OverviewBills')),
  },
  {
    path: 'users',
    component: asyncComponent(() => import('../Users/UserList')),
  },
  {
    path: 'users/:id/edit',
    component: asyncComponent(() => import('../Users/User')),
  },
  {
    path: 'restaurants',
    component: asyncComponent(() => import('../Restaurants/RestaurantList')),
  },
  {
    path: 'restaurants/collections',
    component: asyncComponent(() => import('../Collections/CollectionList')),
  },
  {
    path: 'restaurants/collections/new',
    component: asyncComponent(() => import('../Collections/CollectionNew'))
  },
  {
    path: 'restaurants/collections/:id/edit',
    component: asyncComponent(() => import('../Collections/CollectionEdit'))
  },
  {
    path: 'restaurants/unanswered',
    component: asyncComponent(() => import('../Restaurants/RestaurantUnansweredList')),
  },
  {
    path: 'restaurants/:id/edit',
    component: asyncComponent(() => import('../Restaurants/Restaurant')),
  },
  {
    path: 'bookings',
    component: asyncComponent(() => import('../Bookings/BookingList')),
  },
  {
    path: 'sms-report',
    component: asyncComponent(() => import('../Bookings/SmsReport')),
  },
  {
    path: 'bills',
    component: asyncComponent(() => import('../Bills/BillList')),
  },
  {
    path: 'bills/:id/edit',
    component: asyncComponent(() => import('../Bills/Bill'))
  },
  {
    path: 'bills/:id/recovery',
    component: asyncComponent(() => import('../Bills/BillRecovery')),
  },
  {
    path: 'promos',
    component: asyncComponent(() => import('../Promos/PromoList')),
  },
  {
    path: 'promos/new',
    component: asyncComponent(() => import('../Promos/PromoNew')),
  },
  {
    path: 'promos/:id/edit',
    component: asyncComponent(() => import('../Promos/PromoEdit'))
  },
  {
    path: 'discounts',
    component: asyncComponent(() => import('../Discounts/DiscountPromoList')),
  },
  {
    path: 'discounts/new',
    component: asyncComponent(() => import('../Discounts/DiscountPromoNew')),
  },
  {
    path: 'discounts/:id/edit',
    component: asyncComponent(() => import('../Discounts/DiscountPromoEdit'))
  },
  {
    path: 'gift-cards',
    component: asyncComponent(() => import('../Giftcards/GiftcardList')),
  },
  {
    path: 'gift-cards/new',
    component: asyncComponent(() => import('../Giftcards/GiftcardNew')),
  },
  {
    path: 'companies',
    component: asyncComponent(() => import('../Companies/CompanyList')),
  },
  {
    path: 'companies/new',
    component: asyncComponent(() => import('../Companies/CompanyNew')),
  },
  {
    path: 'companies/:id/edit',
    component: asyncComponent(() => import('../Companies/Company'))
  },
  {
    path: 'marketing/banners',
    component: asyncComponent(() => import('../Banners/BannerList')),
  },
  {
    path: 'marketing/banners/new',
    component: asyncComponent(() => import('../Banners/BannerNew'))
  },
  {
    path: 'marketing/banners/:id/edit',
    component: asyncComponent(() => import('../Banners/BannerEdit'))
  },
  {
    path: 'marketing/app-messages',
    component: asyncComponent(() => import('../AppMessages/AppMessageList')),
  },
  {
    path: 'marketing/app-messages/new',
    component: asyncComponent(() => import('../AppMessages/AppMessageNew')),
  },
  {
    path: 'marketing/app-messages/:id/edit',
    component: asyncComponent(() => import('../AppMessages/AppMessageEdit'))
  },
  {
    path: 'finance/treasury',
    component: asyncComponent(() => import('../Finance/TreasuryInjection')),
  },
  {
    path: 'finance/finance-report',
    component: asyncComponent(() => import('../Finance/FinanceReport')),
  },
  {
    path: 'finance/invoice-report',
    component: asyncComponent(() => import('../Finance/InvoiceReport')),
  },
  {
    path: 'finance/charts',
    component: asyncComponent(() => import('../Finance/FinanceCharts')),
  },
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default AppRouter;
