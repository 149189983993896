import { channel } from 'redux-saga'
import { all, takeEvery, put, fork, call, take } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../settings';
import { getTokenHeader, getToken, showNotificationSavedFailed } from '../../helpers/utility';
import financeConstants from '../../containers/Finance/constants';

export function* getSummary() {
  yield takeEvery(actions.FINANCE_SUMMARY_REQUEST, function*() {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/finance/summary', {
        headers: getTokenHeader()
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('getSummary error'));
      }

      const summary = yield response.json();
      yield put({
        type: actions.FINANCE_SUMMARY_SUCCESS,
        summary
      });
    } 
    catch (e) {
      yield put({ type: actions.FINANCE_ERROR });
      return;
    }
  });
}

export function* getCards() {
  yield takeEvery(actions.FINANCE_TOPUP_CARDS_REQUEST, function*() {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/balance/cards', {
        headers: getTokenHeader()
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('getCards error'));
      }

      const cards = yield response.json();
      yield put({
        type: actions.FINANCE_TOPUP_CARDS_SUCCESS,
        cards
      });
    } 
    catch (e) {
      yield put({ type: actions.FINANCE_ERROR });
      return;
    }
  });
}

export function* topUp() {
  yield takeEvery(actions.FINANCE_TOPUP_REQUEST, function*({data, intl, callback}) {
    try {
      let body = {
        amount: parseInt(data.amount, 10) * 100
      }

      let topupType = data.topup_type;
      if (topupType === financeConstants.TOPUP_TYPE_SEPA) {
        body.method = 'sepa_debit';
      }
      else if (topupType === financeConstants.TOPUP_TYPE_CARD) {
        body.card = data.card;
      }

      const response = yield call(fetch, settings.apiUrl + '/dashboard/balance/topup', {
        method: 'POST',
        headers: getTokenHeader(),
        body: JSON.stringify(body)
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('topUp error'));
      }
  
      const responseData = yield response.json();
      callback(data, responseData);

      yield put({ type: actions.FINANCE_TOPUP_SUCCESS });
    } 
    catch (e) {
      showNotificationSavedFailed(intl);
      yield put({ type: actions.FINANCE_ERROR });
      return;
    }
  });
}

export function* getFinanceReportRestaurants() {
  yield takeEvery(actions.FINANCE_REPORT_RESTAURANTS_REQUEST, function*({ startDate, endDate }) {
    try {
      let url = settings.apiUrl + "/dashboard/finance/overview?start=" + encodeURIComponent(startDate.format('YYYY-MM-DD'))
        + "&end=" + encodeURIComponent(endDate.format('YYYY-MM-DD'));

      const response = yield call(fetch, url, {
        headers: getTokenHeader()
      });

      if (response.status !== 200) {
        throw Object.assign(new Error('getFinanceReportRestaurants error'));
      }

      const responseBody = yield response.json();
      yield put({
        type: actions.FINANCE_REPORT_RESTAURANTS_SUCCESS,
        restaurants: responseBody,
        startDate,
        endDate
      });
    } catch (e) {
      yield put({ type: actions.FINANCE_ERROR });
      return;
    }
  });
}

export function* getSMSReport() {
  yield takeEvery(actions.SMS_REPORT_REQUEST, function*({ startDate, endDate }) {
    try {
      let url = settings.apiUrl + "/dashboard/sms/report?start=" + encodeURIComponent(startDate.format('YYYY-MM-DD'))
        + "&end=" + encodeURIComponent(endDate.format('YYYY-MM-DD'));

      const response = yield call(fetch, url, {
        headers: getTokenHeader()
      });

      if (response.status !== 200) {
        throw Object.assign(new Error('getSMSReport error'));
      }

      const responseBody = yield response.json();
      yield put({
        type: actions.SMS_REPORT_SUCCESS,
        restaurants: responseBody,
        startDate,
        endDate
      });
    } catch (e) {
      yield put({ type: actions.SMS_REPORT_ERROR });
      return;
    }
  });
}

export function* getInvoiceReportBills() {
  yield takeEvery(actions.FINANCE_INVOICE_REPORT_BILLS_REQUEST, function*({ startDate, endDate }) {
    try {
      let url = settings.apiUrl + "/dashboard/invoices?start=" + encodeURIComponent(startDate.format('YYYY-MM-DD'))
        + "&end=" + encodeURIComponent(endDate.format('YYYY-MM-DD'));

      const response = yield call(fetch, url, {
        headers: getTokenHeader()
      });

      if (response.status !== 200) {
        throw Object.assign(new Error('getInvoiceReportBills error'));
      }

      const responseBody = yield response.json();
      yield put({
        type: actions.FINANCE_INVOICE_REPORT_BILLS_SUCCESS,
        invoiceBills: responseBody,
        startDate,
        endDate
      });
    } catch (e) {
      yield put({ type: actions.FINANCE_ERROR });
      return;
    }
  });
}

const downloadFinanceReportChannel = channel()
export function* downloadFinanceReport() {
  yield takeEvery(actions.FINANCE_REPORT_DOWNLOAD_REQUEST, function*({ startDate, endDate }) {
    try {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", settings.apiUrl + "/dashboard/finance/overview?start=" + encodeURIComponent(startDate.format('YYYY-MM-DD'))
        + "&end=" + encodeURIComponent(endDate.format('YYYY-MM-DD')) + "&format=xlsx");
      xhr.responseType = "arraybuffer";
      xhr.onload = function () {
        if (this.status === 200) {
            var blob = new Blob([xhr.response], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            var objectUrl = URL.createObjectURL(blob);

            // Download PDF to file
            var link = document.createElement('a');
            link.href = objectUrl;
            link.download = 'Finance_report_' + startDate.format('YYYY_MM_DD_to_') + endDate.format('YYYY_MM_DD') + '.xlsx';
            link.dispatchEvent(new MouseEvent('click'));

            downloadFinanceReportChannel.put({ type: actions.FINANCE_REPORT_DOWNLOAD_SUCCESS });
        }
      };
      xhr.setRequestHeader("Authorization", 'Bearer ' + getToken().get('idToken'));
      xhr.send();
    } catch (e) {
      yield put({ type: actions.FINANCE_ERROR });
      return;
    }
  });
}

const downloadSMSReportChannel = channel()
export function* downloadSMSReport() {
  yield takeEvery(actions.SMS_REPORT_DOWNLOAD_REQUEST, function*({ startDate, endDate }) {
    try {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", settings.apiUrl + "/dashboard/sms/report?start=" + encodeURIComponent(startDate.format('YYYY-MM-DD'))
        + "&end=" + encodeURIComponent(endDate.format('YYYY-MM-DD')) + "&format=xlsx");
      xhr.responseType = "arraybuffer";
      xhr.onload = function () {
        if (this.status === 200) {
            var blob = new Blob([xhr.response], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            var objectUrl = URL.createObjectURL(blob);

            // Download PDF to file
            var link = document.createElement('a');
            link.href = objectUrl;
            link.download = 'SMS_report_' + startDate.format('YYYY_MM_DD_to_') + endDate.format('YYYY_MM_DD') + '.xlsx';
            link.dispatchEvent(new MouseEvent('click'));

            downloadSMSReportChannel.put({ type: actions.SMS_REPORT_DOWNLOAD_SUCCESS });
        }
      };
      xhr.setRequestHeader("Authorization", 'Bearer ' + getToken().get('idToken'));
      xhr.send();
    } catch (e) {
      yield put({ type: actions.SMS_REPORT_DOWNLOAD_ERROR });
      return;
    }
  });
}

const downloadFinanceAdvancedReportChannel = channel()
export function* downloadFinanceAdvancedReport() {
  yield takeEvery(actions.FINANCE_ADVANCED_REPORT_DOWNLOAD_REQUEST, function*({ startDate, endDate }) {
    try {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", settings.apiUrl + "/dashboard/finance/advanced-report?format=xlsx");
      xhr.responseType = "arraybuffer";
      xhr.onload = function () {
        if (this.status === 200) {
            var blob = new Blob([xhr.response], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            var objectUrl = URL.createObjectURL(blob);

            // Download PDF to file
            var link = document.createElement('a');
            link.href = objectUrl;
            link.download = 'Advanced_report.xlsx';
            link.dispatchEvent(new MouseEvent('click'));

            downloadFinanceAdvancedReportChannel.put({ type: actions.FINANCE_ADVANCED_REPORT_DOWNLOAD_SUCCESS });
        }
      };
      xhr.setRequestHeader("Authorization", 'Bearer ' + getToken().get('idToken'));
      xhr.send();
    } catch (e) {
      yield put({ type: actions.FINANCE_ERROR });
      return;
    }
  });
}

export function* watchDownloadFinanceAdvancedReportChannel() {
  while (true) {
    const action = yield take(downloadFinanceAdvancedReportChannel)
    yield put(action)
  }
}

export function* watchDownloadFinanceReportChannel() {
  while (true) {
    const action = yield take(downloadFinanceReportChannel)
    yield put(action)
  }
}

export function* watchDownloadSMSReportChannel() {
  while (true) {
    const action = yield take(downloadSMSReportChannel)
    yield put(action)
  }
}

const downloadInvoiceReportChannel = channel()
export function* downloadInvoiceReport() {
  yield takeEvery(actions.FINANCE_INVOICE_REPORT_DOWNLOAD_REQUEST, function*({ startDate, endDate }) {
    try {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", settings.apiUrl + "/dashboard/invoices?start=" + encodeURIComponent(startDate.format('YYYY-MM-DD'))
        + "&end=" + encodeURIComponent(endDate.format('YYYY-MM-DD')) + "&format=xlsx");
      xhr.responseType = "arraybuffer";
      xhr.onload = function () {
        if (this.status === 200) {
            var blob = new Blob([xhr.response], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            var objectUrl = URL.createObjectURL(blob);

            // Download PDF to file
            var link = document.createElement('a');
            link.href = objectUrl;
            link.download = 'Invoice_report_' + startDate.format('YYYY_MM_DD_to_') + endDate.format('YYYY_MM_DD') + '.xlsx';
            link.dispatchEvent(new MouseEvent('click'));

            downloadInvoiceReportChannel.put({ type: actions.FINANCE_INVOICE_REPORT_DOWNLOAD_SUCCESS });
        }
      };
      xhr.setRequestHeader("Authorization", 'Bearer ' + getToken().get('idToken'));
      xhr.send();
    } catch (e) {
      yield put({ type: actions.FINANCE_ERROR });
      return;
    }
  });
}

export function* watchDownloadInvoiceReportChannel() {
  while (true) {
    const action = yield take(downloadInvoiceReportChannel)
    yield put(action)
  }
}

export default function* rootSaga() {
  yield all([
    fork(getSummary),
    fork(getCards),
    fork(topUp),
    fork(getFinanceReportRestaurants),
    fork(getSMSReport),
    fork(downloadSMSReport),
    fork(watchDownloadSMSReportChannel),
    fork(downloadFinanceReport),
    fork(watchDownloadFinanceReportChannel),
    fork(downloadFinanceAdvancedReport),
    fork(watchDownloadFinanceAdvancedReportChannel),
    fork(getInvoiceReportBills),
    fork(downloadInvoiceReport),
    fork(watchDownloadInvoiceReportChannel)
  ]);
}
