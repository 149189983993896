const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  USER_REQUEST: 'USER_REQUEST',
  USER_SUCCESS: 'USER_SUCCESS',
  LOGOUT: 'LOGOUT',
  LOGIN_ERROR: 'LOGIN_ERROR',
  USER_ERROR: 'USER_ERROR',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (email, password) => ({
    type: actions.LOGIN_REQUEST,
    email,
    password
  }),
  logout: () => ({
    type: actions.LOGOUT
  }),
  getUser: () => ({
    type: actions.USER_REQUEST
  })
};
export default actions;
