const actions = {
  PROMOS_REQUEST: 'PROMOS_REQUEST',
  PROMOS_SUCCESS: 'PROMOS_SUCCESS',
  PROMOS_DETAILS_REQUEST: 'PROMOS_DETAILS_REQUEST',
  PROMOS_DETAILS_SUCCESS: 'PROMOS_DETAILS_SUCCESS',
  PROMOS_CREATE_REQUEST: 'PROMOS_CREATE_REQUEST',
  PROMOS_CREATE_SUCCESS: 'PROMOS_CREATE_SUCCESS',
  PROMOS_UPDATE_REQUEST: 'PROMOS_UPDATE_REQUEST',
  PROMOS_UPDATE_SUCCESS: 'PROMOS_UPDATE_SUCCESS',
  PROMOS_CATEGORIES_REQUEST: 'PROMOS_CATEGORIES_REQUEST',
  PROMOS_CATEGORIES_SUCCESS: 'PROMOS_CATEGORIES_SUCCESS',
  PROMOS_DISCOUNT_TYPES_REQUEST: 'PROMOS_DISCOUNT_TYPES_REQUEST',
  PROMOS_DISCOUNT_TYPES_SUCCESS: 'PROMOS_DISCOUNT_TYPES_SUCCESS',
  PROMOS_DISCOUNT_TYPES_ADD_REQUEST: 'PROMOS_DISCOUNT_TYPES_ADD_REQUEST',
  PROMOS_DISCOUNT_TYPES_ADD_SUCCESS: 'PROMOS_DISCOUNT_TYPES_ADD_SUCCESS',
  PROMOS_ERROR: 'PROMOS_ERROR',
  PROMOS_CLEAR: 'PROMOS_CLEAR',
  getPromos: (start, count, filter, search, onlyActive, typeIdFilter) => ({
    type: actions.PROMOS_REQUEST,
    start,
    count,
    filter,
    search,
    onlyActive,
    typeIdFilter
  }),
  getPromoDetails: (id) => ({
    type: actions.PROMOS_DETAILS_REQUEST,
    id
  }),
  clearPromos: () => ({
    type: actions.PROMOS_CLEAR
  }),
  createPromo: (data, form, intl) => ({
    type: actions.PROMOS_CREATE_REQUEST,
    data,
    form,
    intl
  }),
  updatePromo: (oldData, newData, intl) => ({
    type: actions.PROMOS_UPDATE_REQUEST,
    oldData,
    newData,
    intl
  }),
  getPromoCategories: () => ({
    type: actions.PROMOS_CATEGORIES_REQUEST
  }),
  getDiscountTypes: () => ({
    type: actions.PROMOS_DISCOUNT_TYPES_REQUEST
  }),
  addDiscountType: (data, intl) => ({
    type: actions.PROMOS_DISCOUNT_TYPES_ADD_REQUEST,
    data,
    intl
  }),
};
export default actions;
