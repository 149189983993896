import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { getToken, setToken, clearToken, getTokenHeader } from '../../helpers/utility';
import actions from './actions';
import settings from '../../settings';

export function* loginRequest() {
  yield takeEvery(actions.LOGIN_REQUEST, function*({email, password}) {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/login', {
        method: 'POST',
        body: JSON.stringify({email, password})
      });

      if (response.status !== 200) {
        yield put({ type: actions.LOGIN_ERROR });
        return;
      }

      const responseBody = yield response.json();

      yield put({
        type: actions.LOGIN_SUCCESS,
        token: responseBody.access_token
      });
    } 
    catch (e) {
        yield put({ type: actions.LOGIN_ERROR });
        return;
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    yield setToken(payload.token);
  });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    clearToken();
    yield put(push('/'));
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const token = getToken().get('idToken');
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: 'Profile'
      });
    }
  });
}

export function* getUser() {
  yield takeEvery(actions.USER_REQUEST, function*() {
    try {
      const response = yield call(fetch, settings.apiUrl + '/user', {
        headers: getTokenHeader()
      });

      if (response.status !== 200) {
        yield put({ type: actions.USER_ERROR });
        return;
      }

      const responseBody = yield response.json();
      yield put({
        type: actions.USER_SUCCESS,
        user: responseBody
      });
    } 
    catch (e) {
        yield put({ type: actions.USER_ERROR });
        return;
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(getUser),
    fork(logout)
  ]);
}
