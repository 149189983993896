import actions from './actions';

const initState = { 
  cards: {
    users: {},
    bills: {},
    bookings: {}
  }
};

export default function overviewReducer(state = initState, action) {
  switch (action.type) {
    
    case actions.CARDS_SUCCESS:
      return { ...state, cards: action.cards };

    case actions.CHART_TOP_RESTAURANTS_CLOSED_BILLS_REQUEST:
      return { ...state, chartTopRestaurantsClosedBillsIsLoading: true };

    case actions.CHART_TOP_RESTAURANTS_CLOSED_BILLS_SUCCESS:
      return { ...state, chartTopRestaurantsClosedBillsIsLoading: false, chartTopRestaurantsClosedBills: action.chart };

    case actions.CHART_CLOSED_BILLS_REQUEST:
      return { ...state, chartClosedBillsIsLoading: true };

    case actions.CHART_CLOSED_BILLS_SUCCESS:
      return { ...state, chartClosedBillsIsLoading: false, chartClosedBills: action.chart };

    case actions.CHART_BILLS_WITH_CREDITS_REQUEST:
      return { ...state, chartBillsWithCreditsIsLoading: true };

    case actions.CHART_BILLS_WITH_CREDITS_SUCCESS:
      return { ...state, chartBillsWithCreditsIsLoading: false, chartBillsWithCredits: action.chart };

    case actions.CHART_USED_PAYMENT_METHODS_REQUEST:
      return { ...state, chartUsedPaymentMethodsIsLoading: true };

    case actions.CHART_USED_PAYMENT_METHODS_SUCCESS:
      return { ...state, chartUsedPaymentMethodsIsLoading: false, chartUsedPaymentMethods: action.chart };

    case actions.CHART_TURNOVER_PAYMENT_METHODS_REQUEST:
      return { ...state, chartTurnoverPaymentMethodsIsLoading: true };

    case actions.CHART_TURNOVER_PAYMENT_METHODS_SUCCESS:
      return { ...state, chartTurnoverPaymentMethodsIsLoading: false, chartTurnoverPaymentMethods: action.chart };

    case actions.CHART_CREDIT_TURNOVER_REQUEST:
      return { ...state, chartCreditTurnoverIsLoading: true };

    case actions.CHART_CREDIT_TURNOVER_SUCCESS:
      return { ...state, chartCreditTurnoverIsLoading: false, chartCreditTurnover: action.chart };

    case actions.CHART_NEW_USERS_REQUEST:
      return { ...state, chartNewUsersIsLoading: true };

    case actions.CHART_NEW_USERS_SUCCESS:
      return { ...state, chartNewUsersIsLoading: false, chartNewUsers: action.chart };

    case actions.CHART_BOOKINGS_REQUEST:
      return { ...state, chartBookingsIsLoading: true };

    case actions.CHART_BOOKINGS_SUCCESS:
      return { ...state, chartBookingsIsLoading: false, chartBookings: action.chart };

    case actions.CHART_TURNOVER_AND_REVENUE_REQUEST:
      return { ...state, chartTurnoverAndRevenueIsLoading: true };

    case actions.CHART_TURNOVER_AND_REVENUE_SUCCESS:
      return { ...state, chartTurnoverAndRevenueIsLoading: false, chartTurnoverAndRevenue: action.chart };

    case actions.OVERVIEW_CLEAR:
      return { ...initState };

    default:
      return state;
  }
}
