import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../settings';
import { getTokenHeader, showNotificationSavedSuccess, showNotificationSavedFailed } from '../../helpers/utility';

export function* getCompanies() {
  yield takeEvery(actions.COMPANIES_REQUEST, function*({start, count, search}) {
    try {
      let url = settings.apiUrl + '/dashboard/companies?start=' + start + '&count=' + count;

      if (search) {
        url += "&q=" + encodeURIComponent(search);
      }

      const response = yield call(fetch, url, {
        headers: getTokenHeader()
      });

      if (response.status !== 200) {
        yield put({ type: actions.COMPANIES_ERROR });
        return;
      }

      const responseBody = yield response.json();
      yield put({
        type: actions.COMPANIES_SUCCESS,
        companies: responseBody,
        search,
        start,
      });

    } 
    catch (e) {
        yield put({ type: actions.COMPANIES_ERROR });
        return;
    }
  });
}

export function* getCompanyDetails() {
  yield takeEvery(actions.COMPANIES_DETAILS_REQUEST, function*({id, callback}) {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/companies/' + id, {
        headers: getTokenHeader()
      });

      if (response.status !== 200) {
        yield put({ type: actions.COMPANIES_ERROR });
        return;
      }

      const company = yield response.json();

      if (callback) {
        callback();
      }

      yield put({
        type: actions.COMPANIES_DETAILS_SUCCESS,
        company
      });

    } 
    catch (e) {
        yield put({ type: actions.COMPANIES_ERROR });
        return;
    }
  });
}

export function* updateCompany() {
  yield takeEvery(actions.COMPANIES_UPDATE_REQUEST, function*({id, data, intl}) {
    try {

      const response = yield call(fetch, settings.apiUrl + '/dashboard/companies/' + id, {
        method: 'PATCH',
        headers: getTokenHeader(),
        body: JSON.stringify(data)
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('updateCompany error'));
      }
  
      const company = yield response.json();
      yield put({
        type: actions.COMPANIES_UPDATE_SUCCESS,
        company
      });

      showNotificationSavedSuccess(intl);
    } 
    catch (e) {
      showNotificationSavedFailed(intl);
      yield put({ type: actions.COMPANIES_ERROR });
      return;
    }
  });
}

export function* createCompany() {
  yield takeEvery(actions.COMPANIES_CREATE_REQUEST, function*({data, form, intl}) {
    try {

      const response = yield call(fetch, settings.apiUrl + '/dashboard/companies', {
        method: 'POST',
        headers: getTokenHeader(),
        body: JSON.stringify(data)
      });

      if (response.status !== 200) {
        yield put({ type: actions.COMPANIES_ERROR });
        showNotificationSavedFailed(intl);
        return;
      }

      const company = yield response.json();
      yield put({
        type: actions.COMPANIES_CREATE_SUCCESS,
        company
      });

      showNotificationSavedSuccess(intl);
      form.resetFields();
    } 
    catch (e) {
        yield put({ type: actions.COMPANIES_ERROR });
        showNotificationSavedFailed(intl);
        return;
    }
  });
}

export function* getCompanyUsers(companyId) {
  try {
    const response = yield call(fetch, settings.apiUrl + '/dashboard/companies/' + companyId + '/users', {
      headers: getTokenHeader()
    });

    if (response.status !== 200) {
      yield put({ type: actions.COMPANIES_ERROR });
      return;
    }

    const users = yield response.json();
    yield put({
      type: actions.COMPANIES_USERS_SUCCESS,
      users
    });

    return users;
  } 
  catch (e) {
      yield put({ type: actions.COMPANIES_ERROR });
      return;
  }
}

function* watchGetCompanyUsers() {
  yield takeEvery(actions.COMPANIES_USERS_REQUEST, function*({ companyId }) {
    yield call(getCompanyUsers, companyId);
  });
}

export function* addCompanyUser() {
  yield takeEvery(actions.COMPANIES_USERS_ADD_REQUEST, function*({companyId, userId, intl}) {
    try {

      var data = {
        user_ids: [parseInt(userId, 10)]
      };

      const response = yield call(fetch, settings.apiUrl + '/dashboard/companies/' + companyId + '/users', {
        method: 'POST',
        headers: getTokenHeader(),
        body: JSON.stringify(data)
      });

      if (response.status !== 200) {
        yield put({ type: actions.COMPANIES_ERROR });
        showNotificationSavedFailed(intl);
        return;
      }

      yield call(getCompanyUsers, companyId);
      yield put({type: actions.COMPANIES_USERS_ADD_SUCCESS});
      showNotificationSavedSuccess(intl);
    } 
    catch (e) {
        yield put({ type: actions.COMPANIES_ERROR });
        showNotificationSavedFailed(intl);
        return;
    }
  });
}

export function* removeCompanyUser() {
  yield takeEvery(actions.COMPANIES_USERS_REMOVE_REQUEST, function*({companyId, userId, intl}) {
    try {

      var data = {
        user_ids: [parseInt(userId, 10)]
      };

      const response = yield call(fetch, settings.apiUrl + '/dashboard/companies/' + companyId + '/users', {
        method: 'DELETE',
        headers: getTokenHeader(),
        body: JSON.stringify(data)
      });

      if (response.status !== 200) {
        yield put({ type: actions.COMPANIES_ERROR });
        showNotificationSavedFailed(intl);
        return;
      }

      yield call(getCompanyUsers, companyId);
      yield put({type: actions.COMPANIES_USERS_REMOVE_SUCCESS});
      showNotificationSavedSuccess(intl);
    } 
    catch (e) {
        yield put({ type: actions.COMPANIES_ERROR });
        showNotificationSavedFailed(intl);
        return;
    }
  });
}

export function* getCompanyBills() {
  yield takeEvery(actions.COMPANIES_BILLS_REQUEST, function*({companyId, start, count}) {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/companies/' + companyId + '/bills?start=' + start + '&count=' + count, {
        headers: getTokenHeader()
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('getCompanyBills error'));
      }

      const bills = yield response.json();
      yield put({
        type: actions.COMPANIES_BILLS_SUCCESS,
        bills,
        start
      });
    } 
    catch (e) {
      yield put({ type: actions.COMPANIES_ERROR });
      return;
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getCompanies),
    fork(getCompanyDetails),
    fork(updateCompany),
    fork(createCompany),
    fork(addCompanyUser),
    fork(removeCompanyUser),
    fork(watchGetCompanyUsers),
    fork(getCompanyBills)
  ]);
}
