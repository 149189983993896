export default {
  apiUrl: process.env.REACT_APP_API_URL,
  isProd: process.env.REACT_APP_API_URL.indexOf('stg') < 0,
  hovWebUrl: process.env.REACT_APP_API_URL.indexOf('stg') < 0 ? 'https://hov.maitres.com/' : 'https://mtrs-web-stg.herokuapp.com/'
};

const siteConfig = {
  siteName: 'Maîtres',
  siteIcon: 'ion-flash',
  firstPage: '/dashboard/overview-today',
};

const themeConfig = {
  topbar: 'thememaitres',
  sidebar: 'thememaitres',
  layout: 'thememaitres',
  theme: 'thememaitres',
};

const language = 'english';

const jwtConfig = {
  fetchUrl: '/api/',
  secretKey: 'secretKey',
};

export { siteConfig, language, themeConfig, jwtConfig };
