import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../settings';
import promoConstants from '../../containers/Promos/constants';
import { getTokenHeader, showNotificationSavedSuccess, showNotificationSavedFailed } from '../../helpers/utility';

export function* getPromos() {
  yield takeEvery(actions.PROMOS_REQUEST, function*({start, count, filter, search, onlyActive, typeIdFilter}) {
    try {

      let url = settings.apiUrl + '/dashboard/promos?' +
      'start=' + start +
      '&count=' + count +
      '&filter=' + (filter || 'all');
      if (search) {
        url += '&q=' + encodeURIComponent(search);
      }

      if (onlyActive) {
        url += '&is_active=' + onlyActive;
      }

      if (typeIdFilter) {
        url += '&type_id=' + typeIdFilter;
      }

      const response = yield call(fetch, url, {
        headers: getTokenHeader()
      });

      if (response.status !== 200) {
        yield put({ type: actions.PROMOS_ERROR });
        return;
      }

      const responseBody = yield response.json();
      yield put({
        type: actions.PROMOS_SUCCESS,
        promos: responseBody,
        filter: filter,
        search: search,
        start,
        onlyActive,
        typeIdFilter
      });

    } 
    catch (e) {
        yield put({ type: actions.PROMOS_ERROR });
        return;
    }
  });
}

export function* getPromoDetails() {
  yield takeEvery(actions.PROMOS_DETAILS_REQUEST, function*({id}) {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/promos/' + id, {
        headers: getTokenHeader()
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('getPromoDetails error'));
      }

      const promo = yield response.json();
      yield put({
        type: actions.PROMOS_DETAILS_SUCCESS,
        promo
      });
    } 
    catch (e) {
      yield put({ type: actions.PROMOS_ERROR });
      return;
    }
  });
}

export function* createPromo() {
  yield takeEvery(actions.PROMOS_CREATE_REQUEST, function*({data, form, intl}) {
    try {

      let body = getPromoRequestBody(data);

      // Move date to first second of next day
      if (body.expires_at) {
        body.expires_at = body.expires_at.add(1, "days").startOf("day").format();
      }

      const response = yield call(fetch, settings.apiUrl + '/dashboard/promos', {
        method: 'POST',
        headers: getTokenHeader(),
        body: JSON.stringify(body)
      });

      if (response.status !== 200) {
        yield put({ type: actions.PROMOS_ERROR });
        showNotificationSavedFailed(intl);
        return;
      }

      const responseBody = yield response.json();
      yield put({
        type: actions.PROMOS_CREATE_SUCCESS,
        promo: responseBody
      });

      showNotificationSavedSuccess(intl);
      form.resetFields();
    } 
    catch (e) {
        yield put({ type: actions.PROMOS_ERROR });
        showNotificationSavedFailed(intl);
        return;
    }
  });
}

export function* updatePromo() {
  yield takeEvery(actions.PROMOS_UPDATE_REQUEST, function*({oldData, newData, intl}) {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/promos/' + oldData.id, {
        method: 'PATCH',
        headers: getTokenHeader(),
        body: JSON.stringify(getPromoRequestBody(newData))
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('updatePromo error'));
      }
  
      const promo = yield response.json();
      yield put({
        type: actions.PROMOS_UPDATE_SUCCESS,
        promo
      });

      showNotificationSavedSuccess(intl);
    } 
    catch (e) {
      showNotificationSavedFailed(intl);
      yield put({ type: actions.PROMOS_ERROR });
      return;
    }
  });
}

export function* getPromoCategories() {
  yield takeEvery(actions.PROMOS_CATEGORIES_REQUEST, function*() {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/categories/promo', {
        headers: getTokenHeader()
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('getPromoCategories error'));
      }

      const categories = yield response.json();
      yield put({
        type: actions.PROMOS_CATEGORIES_SUCCESS,
        categories
      });
    } 
    catch (e) {
      yield put({ type: actions.PROMOS_ERROR });
      return;
    }
  });
}

export function* getDiscountTypes() {
  try {
    const response = yield call(fetch, settings.apiUrl + '/dashboard/discount/types', {
      headers: getTokenHeader()
    });

    if (response.status !== 200) {
      throw Object.assign(new Error('getDiscountTypes error'));
    }

    const discountTypes = yield response.json();
    yield put({
      type: actions.PROMOS_DISCOUNT_TYPES_SUCCESS,
      discountTypes
    });
  } 
  catch (e) {
    yield put({ type: actions.PROMOS_ERROR });
    return;
  }
}

function* watchDiscountTypes() {
  yield takeEvery(actions.PROMOS_DISCOUNT_TYPES_REQUEST, function*() {
    yield call(getDiscountTypes);
  });
}

export function* addDiscountType() {
  yield takeEvery(actions.PROMOS_DISCOUNT_TYPES_ADD_REQUEST, function*({data, intl}) {
    try {

      let body = {
        names: data.names,
        value: parseInt(data.value, 10),
        show_percent: data.show_percent,
        restaurant_ids: []
      }

      const response = yield call(fetch, settings.apiUrl + '/dashboard/discount/types', {
        method: 'POST',
        headers: getTokenHeader(),
        body: JSON.stringify(body)
      });

      if (response.status !== 200) {
        throw Object.assign(new Error('addDiscountType error'));
      }

      yield put({
        type: actions.PROMOS_DISCOUNT_TYPES_ADD_SUCCESS,
      });

      showNotificationSavedSuccess(intl);

      // Refresh discount types
      yield call(getDiscountTypes);
    } 
    catch (e) {
        yield put({ type: actions.PROMOS_ERROR });
        showNotificationSavedFailed(intl);
        return;
    }
  });
}

function getPromoRequestBody(data) {
  let body = {
    code: data.code,
    total: parseInt(data.total, 10),
    expires_at: data.expires_at.utc(),
    category: data.category
  };

  if (data.amount) {
    if (data.code_type !== promoConstants.CODE_TYPE_FIXED_PERCENTAGE) {
      body.credits = parseInt(data.amount * 100, 10);
    }
    else {
      body.credits = parseInt(data.amount, 10);
    }
  }
  
  let hasSpecificRestaurants = data.restaurants && data.restaurants.length > 0;
  if (data.code_type) {
    if (data.code_type === promoConstants.CODE_TYPE_CREDIT) {
      if (hasSpecificRestaurants) {
        body.restaurant_ids = data.restaurants;
        body.credit_type = !data.is_super ? 1 : 7;
      }
      else {
        body.credit_type = !data.is_super ? 2 : 8;
      }
    }
    else if (data.code_type === promoConstants.CODE_TYPE_FIXED) {
      if (hasSpecificRestaurants) {
        body.restaurant_ids = data.restaurants;
        body.credit_type = 3;
      }
      else {
        body.credit_type = 4;
      }
    }
    else if (data.code_type === promoConstants.CODE_TYPE_FIXED_PERCENTAGE) {
      if (hasSpecificRestaurants) {
        body.restaurant_ids = data.restaurants;
        body.credit_type = 5;
      }
      else {
        body.credit_type = 6;
      }
    }
  }
  else if (data.discount_type) {
    body.discount_type = data.discount_type;
    body.restaurant_ids = data.restaurants;
  }

  return body;
}

export default function* rootSaga() {
  yield all([
    fork(getPromos),
    fork(getPromoDetails),
    fork(createPromo),
    fork(updatePromo),
    fork(getPromoCategories),
    fork(watchDiscountTypes),
    fork(addDiscountType)
  ]);
}
