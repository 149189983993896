const actions = {
  RESTAURANTS_REQUEST: 'RESTAURANTS_REQUEST',
  RESTAURANTS_SUCCESS: 'RESTAURANTS_SUCCESS',
  RESTAURANTS_DETAILS_REQUEST: 'RESTAURANTS_DETAILS_REQUEST',
  RESTAURANTS_DETAILS_SUCCESS: 'RESTAURANTS_DETAILS_SUCCESS',
  RESTAURANTS_UPDATE_REQUEST: 'RESTAURANTS_UPDATE_REQUEST',
  RESTAURANTS_UPDATE_SUCCESS: 'RESTAURANTS_UPDATE_SUCCESS',
  RESTAURANTS_UPDATE_IMAGE_REQUEST: 'RESTAURANTS_UPDATE_IMAGE_REQUEST',
  RESTAURANTS_UPDATE_IMAGE_SUCCESS: 'RESTAURANTS_UPDATE_IMAGE_SUCCESS',
  TAGS_GET_REQUEST: 'TAGS_GET_REQUEST',
  TAGS_GET_SUCCESS: 'TAGS_GET_SUCCESS',
  RESTAURANTS_TAG_ADD_REQUEST: 'RESTAURANTS_TAG_ADD_REQUEST',
  RESTAURANTS_TAG_ADD_SUCCESS: 'RESTAURANTS_TAG_ADD_SUCCESS',
  RESTAURANTS_TAG_UPDATE_REQUEST: 'RESTAURANTS_TAG_UPDATE_REQUEST',
  RESTAURANTS_TAG_UPDATE_SUCCESS: 'RESTAURANTS_TAG_UPDATE_SUCCESS',
  RESTAURANTS_TAG_MOVE_REQUEST: 'RESTAURANTS_TAG_MOVE_REQUEST',
  RESTAURANTS_TAG_MOVE_SUCCESS: 'RESTAURANTS_TAG_MOVE_SUCCESS',
  RESTAURANTS_TAG_DELETE_REQUEST: 'RESTAURANTS_TAG_DELETE_REQUEST',
  RESTAURANTS_TAG_DELETE_SUCCESS: 'RESTAURANTS_TAG_DELETE_SUCCESS',
  RESTAURANTS_UNANSWERED_REQUEST: 'RESTAURANTS_UNANSWERED_REQUEST',
  RESTAURANTS_UNANSWERED_SUCCESS: 'RESTAURANTS_UNANSWERED_SUCCESS',
  RESTAURANTS_BILLS_REQUEST: 'RESTAURANTS_BILLS_REQUEST',
  RESTAURANTS_BILLS_SUCCESS: 'RESTAURANTS_BILLS_SUCCESS',
  RESTAURANTS_PAYOUTS_REQUEST: 'RESTAURANTS_PAYOUTS_REQUEST',
  RESTAURANTS_PAYOUTS_SUCCESS: 'RESTAURANTS_PAYOUTS_SUCCESS',
  RESTAURANTS_PAYOUT_DOWNLOAD_REQUEST: 'RESTAURANTS_PAYOUT_DOWNLOAD_REQUEST',
  RESTAURANTS_PAYOUT_DOWNLOAD_SUCCESS: 'RESTAURANTS_PAYOUT_DOWNLOAD_SUCCESS',
  RESTAURANTS_EMPLOYEES_REQUEST: 'RESTAURANTS_EMPLOYEES_REQUEST',
  RESTAURANTS_EMPLOYEES_SUCCESS: 'RESTAURANTS_EMPLOYEES_SUCCESS',
  RESTAURANTS_OPENING_HOURS_DEFAULT_REQUEST: 'RESTAURANTS_OPENING_HOURS_DEFAULT_REQUEST',
  RESTAURANTS_OPENING_HOURS_DEFAULT_SUCCESS: 'RESTAURANTS_OPENING_HOURS_DEFAULT_SUCCESS',
  RESTAURANTS_OPENING_HOURS_ADD_REQUEST: 'RESTAURANTS_OPENING_HOURS_ADD_REQUEST',
  RESTAURANTS_OPENING_HOURS_ADD_SUCCESS: 'RESTAURANTS_OPENING_HOURS_ADD_SUCCESS',
  RESTAURANTS_OPENING_HOURS_DELETE_REQUEST: 'RESTAURANTS_OPENING_HOURS_DELETE_REQUEST',
  RESTAURANTS_OPENING_HOURS_DELETE_SUCCESS: 'RESTAURANTS_OPENING_HOURS_DELETE_SUCCESS',
  RESTAURANTS_OPENING_HOURS_SAVE_REQUEST: 'RESTAURANTS_OPENING_HOURS_SAVE_REQUEST',
  RESTAURANTS_OPENING_HOURS_SAVE_SUCCESS: 'RESTAURANTS_OPENING_HOURS_SAVE_SUCCESS',
  RESTAURANTS_OPENING_HOURS_EXCEPTION_REQUEST: 'RESTAURANTS_OPENING_HOURS_EXCEPTION_REQUEST',
  RESTAURANTS_OPENING_HOURS_EXCEPTION_SUCCESS: 'RESTAURANTS_OPENING_HOURS_EXCEPTION_SUCCESS',
  RESTAURANTS_OPENING_HOURS_EXCEPTION_ADD_REQUEST: 'RESTAURANTS_OPENING_HOURS_EXCEPTION_ADD_REQUEST',
  RESTAURANTS_OPENING_HOURS_EXCEPTION_ADD_SUCCESS: 'RESTAURANTS_OPENING_HOURS_EXCEPTION_ADD_SUCCESS',
  RESTAURANTS_OPENING_HOURS_EXCEPTION_DELETE_REQUEST: 'RESTAURANTS_OPENING_HOURS_EXCEPTION_DELETE_REQUEST',
  RESTAURANTS_OPENING_HOURS_EXCEPTION_DELETE_SUCCESS: 'RESTAURANTS_OPENING_HOURS_EXCEPTION_DELETE_SUCCESS',
  RESTAURANTS_ERROR: 'RESTAURANTS_ERROR',
  RESTAURANTS_CLEAR: 'RESTAURANTS_CLEAR',
  getRestaurants: (search) => ({
    type: actions.RESTAURANTS_REQUEST,
    search
  }),
  getRestaurantDetails: (id, callback) => ({
    type: actions.RESTAURANTS_DETAILS_REQUEST,
    id,
    callback
  }),
  updateRestaurant: (id, data, intl) => ({
    type: actions.RESTAURANTS_UPDATE_REQUEST,
    id,
    data,
    intl
  }),
  updateRestaurantImage: (id, imageFile, intl) => ({
    type: actions.RESTAURANTS_UPDATE_IMAGE_REQUEST,
    id,
    imageFile,
    intl
  }),
  getTags: () => ({
    type: actions.TAGS_GET_REQUEST
  }),
  addRestaurantTag: (restaurantId, tag) => ({
    type: actions.RESTAURANTS_TAG_ADD_REQUEST,
    restaurantId,
    tag
  }),
  updateRestaurantTag: (restaurantId, tag) => ({
    type: actions.RESTAURANTS_TAG_UPDATE_REQUEST,
    restaurantId,
    tag
  }),
  moveRestaurantTag: (restaurantId, tag, oldPos, newPos) => ({
    type: actions.RESTAURANTS_TAG_MOVE_REQUEST,
    restaurantId,
    tag,
    oldPos,
    newPos
  }),
  deleteRestaurantTag: (restaurantId, tag) => ({
    type: actions.RESTAURANTS_TAG_DELETE_REQUEST,
    restaurantId,
    tag
  }),
  getRestaurantsUnanswered: () => ({
    type: actions.RESTAURANTS_UNANSWERED_REQUEST
  }),
  getRestaurantBills: (restaurantId, start, count) => ({
    type: actions.RESTAURANTS_BILLS_REQUEST,
    restaurantId,
    start,
    count
  }),
  getRestaurantPayouts: (restaurantId, start, count) => ({
    type: actions.RESTAURANTS_PAYOUTS_REQUEST,
    restaurantId,
    start,
    count
  }),
  getRestaurantPayoutPdf: (reportId, url) => ({
    type: actions.RESTAURANTS_PAYOUT_DOWNLOAD_REQUEST,
    reportId,
    url
  }),
  getRestaurantEmployees: (restaurantId) => ({
    type: actions.RESTAURANTS_EMPLOYEES_REQUEST,
    restaurantId
  }),
  getRestaurantDefaultOpenHours: (restaurantId) => ({
    type: actions.RESTAURANTS_OPENING_HOURS_DEFAULT_REQUEST,
    restaurantId
  }),
  addRestaurantOpenHour: (restaurantId, day, from, to) => ({
    type: actions.RESTAURANTS_OPENING_HOURS_ADD_REQUEST,
    restaurantId,
    day,
    from,
    to
  }),
  deleteRestaurantOpenHour: (restaurantId, day, from, to) => ({
    type: actions.RESTAURANTS_OPENING_HOURS_DELETE_REQUEST,
    restaurantId,
    day,
    from,
    to
  }),
  saveRestaurantOpenHours: (restaurantId, intl) => ({
    type: actions.RESTAURANTS_OPENING_HOURS_SAVE_REQUEST,
    restaurantId,
    intl
  }),
  getRestaurantOpenHourExceptions: (restaurantId) => ({
    type: actions.RESTAURANTS_OPENING_HOURS_EXCEPTION_REQUEST,
    restaurantId
  }),
  addRestaurantOpenHourException: (restaurantId, fromDate, toDate, isClosedHours, intl) => ({
    type: actions.RESTAURANTS_OPENING_HOURS_EXCEPTION_ADD_REQUEST,
    restaurantId,
    fromDate,
    toDate,
    isClosedHours,
    intl
  }),
  deleteRestaurantOpenHourException: (restaurantId, fromDate, toDate, intl) => ({
    type: actions.RESTAURANTS_OPENING_HOURS_EXCEPTION_DELETE_REQUEST,
    restaurantId,
    fromDate,
    toDate,
    intl
  }),
  clearRestaurants: () => ({
    type: actions.RESTAURANTS_CLEAR
  }),
};
export default actions;
