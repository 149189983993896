import actions from './actions';

const initState = { 
  bookings: [],
  hasMoreBookings: true,
};

export default function overviewReducer(state = initState, action) {
  switch (action.type) {

    case actions.BOOKINGS_REQUEST:
        var requestState = {
          ...state,
          hasMoreBookings: true,
          isLoading: true
        };
  
        // If new filter or other search, clean data
        if (state.search !== action.search || state.startTime !== action.startTime || state.endTime !== action.endTime) {
          requestState.bookings = [];
        }
  
        return requestState;

    case actions.BOOKINGS_SUCCESS:
      return {
        ...state,
        hasMoreBookings: action.bookings.length > 0,
        isLoading: false,
        bookings:
          state.bookings && action.start > 0
            ? state.bookings.concat(action.bookings)
            : action.bookings,
        search: action.search,
        startTime: action.startTime,
        endTime: action.endTime
      };

    case actions.BOOKINGS_CLEAR:
      return { ...state, bookings: [] };

    case actions.BOOKINGS_ERROR:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}
