import actions from "./actions";

const initState = {
  bills: [],
  bill: {},
  hasMoreBills: true,
  recoveryBillOrders: []
};

export default function overviewReducer(state = initState, action) {
  state.deletedBillId = undefined;

  switch (action.type) {
    case actions.BILLS_REQUEST:
      var requestState = {
        ...state,
        hasMoreBills: true,
        isLoading: true
      };

      // If new filter or other search, clean data
      if (state.filter !== action.filter || state.search !== action.search 
        || state.startTime !== action.startTime || state.endTime !== action.endTime) {
        requestState.bills = [];
      }

      return requestState;

    case actions.BILLS_SUCCESS:
      return {
        ...state,
        hasMoreBills: action.bills.length > 0,
        isLoading: false,
        bills:
          state.bills && action.start > 0
            ? state.bills.concat(action.bills)
            : action.bills,
        filter: action.filter,
        search: action.search,
        startTime: action.startTime,
        endTime: action.endTime
      };

    case actions.BILL_DETAILS_REQUEST:
      return { ...state };

    case actions.BILL_DETAILS_SUCCESS:
      return { ...state, bill: action.bill, isLoading: false };

    case actions.BILL_MANAGE_REQUEST:
      return { ...state, isLoading: true };

    case actions.BILL_MANAGE_SUCCESS:
      return { ...state, bill: action.bill, isLoading: false };

    case actions.BILL_DELETE_SUCCESS:
      var bills = state.bills;
      var deletedIndex = bills.findIndex(x => x.id === action.deletedBillId);
      if (deletedIndex > -1) {
        bills.splice(deletedIndex, 1);
      }

      return {
        ...state,
        bills,
        isLoading: false,
        deletedBillId: action.deletedBillId
      };

    case actions.BILL_RECOVERY_DETAILS_REQUEST:
      return { ...state, recoveryOrdersIsLoading: true };

    case actions.BILL_RECOVERY_DETAILS_SUCCESS:
      return { ...state, recoveryOrdersIsLoading: false, recoveryBillOrders: action.recoveryBillOrders || [] };

    case actions.BILL_RECOVERY_PROCESS_REQUEST:
      return { ...state, recoveryIsLoading: true };

    case actions.BILL_RECOVERY_PROCESS_SUCCESS:
      return { ...state, recoveryIsLoading: false, recoveryStatus: 'success' };

    case actions.BILL_RECOVERY_SEARCH_REQUEST:
      return { ...state, recoveryIsLoading: true };

    case actions.BILL_RECOVERY_SEARCH_SUCCESS:
      return { ...state, recoveryIsLoading: false, filteredRecoveryBillOrders: action.filteredRecoveryBillOrders };

    case actions.BILL_RECOVERY_PROCESS_ERROR:
      return { ...state, recoveryIsLoading: false, recoveryStatus: 'failed' };

    case actions.BILLS_CLEAR:
      return { ...state, bills: [], bill: {}, recoveryBillOrders: [], filteredRecoveryBillOrders: null, recoveryStatus: null };

    case actions.BILLS_ERROR:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}
