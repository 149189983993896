import React from "react";
import { Provider } from "react-redux";
import { store, history } from "./redux/store";
import PublicRoutes from "./router";
import { ThemeProvider } from "styled-components";
import { LocaleProvider } from "antd";
import { IntlProvider } from "react-intl";
import themes from "./settings/themes";
import AppLocale from "./languageProvider";
import { getCurrentLanguage } from "./containers/LanguageSwitcher/config";
import { themeConfig } from "./settings";
import DashAppHolder from "./dashAppStyle";
import Boot from "./redux/boot";
import HttpsRedirect from 'react-https-redirect';

const currentAppLocale =
  AppLocale[getCurrentLanguage().locale];

const DashApp = () => (
  <HttpsRedirect>
    <LocaleProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ThemeProvider theme={themes[themeConfig.theme]}>
          <DashAppHolder>
            <Provider store={store}>
              <PublicRoutes history={history} />
            </Provider>
          </DashAppHolder>
        </ThemeProvider>
      </IntlProvider>
    </LocaleProvider>
  </HttpsRedirect>
);
Boot()
  .then(() => DashApp())
  .catch(error => console.error(error));

export default DashApp;
export { AppLocale };
