import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popover from '../../components/uielements/popover';
import IntlMessages from '../../components/utility/intlMessages';
import authAction from '../../redux/auth/actions';
import TopbarDropdownWrapper from './topbarDropdown.style';
import { Avatar } from 'antd';

const { getUser, logout } = authAction;

class TopbarUser extends Component {

  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    this.props.getUser();
  }

  hide() {
    this.setState({ visible: false });
  }

  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  render() {

    const { user } = this.props;
    const dummyLink = '#';

    const content = (
      <div>
        <TopbarDropdownWrapper className="isoUserDropdown">
          <div className="isoDropdownHeader">
            <h3>{user ? user.first_name + ' ' + user.last_name : ''}</h3>
            <h4>{user ? user.email : ''}</h4>
          </div>
          <a className="isoDropdownLink" onClick={this.props.logout} href={dummyLink}>
            <IntlMessages id="topbar.logout" />
          </a>
        </TopbarDropdownWrapper>
      </div>
    );

    let avatarProps = user && user.image ? { src: user.image.url } : { icon: "user" }

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div className="isoImgWrapper">
          <Avatar shape="circle" size="large" {...avatarProps} />
          <span className="userActivity online" />
        </div>
      </Popover>
    );
  }
}
export default connect(
  state => ({
    user: state.Auth.user,
  }),
  { getUser, logout }
)(TopbarUser);
