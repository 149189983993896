import actions from "./actions";

const initState = {
  promos: [],
  promo: {},
  categories: [],
  discountTypes: [],
  hasMorePromos: true
};

export default function overviewReducer(state = initState, action) {

  switch (action.type) {
    case actions.PROMOS_REQUEST:
      var requestState = {
        ...state,
        hasMorePromos: true,
        isLoading: true
      };

      // If new filter or other search, clean data
      if (state.filter !== action.filter || state.search !== action.search || state.onlyActive !== action.onlyActive) {
        requestState.promos = [];
      }

      return requestState;

    case actions.PROMOS_SUCCESS:
      return {
        ...state,
        hasMorePromos: action.promos.length > 0,
        isLoading: false,
        promos:
          state.promos && action.start > 0
            ? state.promos.concat(action.promos)
            : action.promos,
        filter: action.filter,
        search: action.search,
        onlyActive: action.onlyActive,
        typeIdFilter: action.typeIdFilter
      };

    case actions.PROMOS_DETAILS_REQUEST:
      return { ...state, isLoading: true };

    case actions.PROMOS_DETAILS_SUCCESS:
      return { 
        ...state, 
        isLoading: false,
        promo: action.promo
      };

    case actions.PROMOS_CREATE_REQUEST:
      return { ...state, isLoading: true };

    case actions.PROMOS_CREATE_SUCCESS:
      return { ...state, isLoading: false, promo: action.promo };

    case actions.PROMOS_UPDATE_REQUEST:
      return { ...state, isLoading: true };

    case actions.PROMOS_UPDATE_SUCCESS:
      return { ...state, isLoading: false, promo: action.promo };

    case actions.PROMOS_CATEGORIES_SUCCESS:
      return { ...state, categories: action.categories };

    case actions.PROMOS_DISCOUNT_TYPES_SUCCESS:
      return { ...state, discountTypes: action.discountTypes };

    case actions.PROMOS_DISCOUNT_TYPES_ADD_REQUEST:
      return { ...state, isLoading: true };
    
    case actions.PROMOS_DISCOUNT_TYPES_ADD_SUCCESS:
      return { ...state, isLoading: false };

    case actions.PROMOS_CLEAR:
      return { ...state, promos: [], promo: {}, categories: [], discountTypes: [] };

    case actions.PROMOS_ERROR:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}
