import actions from './actions';
import billActions from "../bills/actions";

const initState = { 
  restaurants: [],
  restaurant: {},
  hasMoreRestaurantBills: true,
  hasMoreRestaurantPayouts: true
};

export default function overviewReducer(state = initState, action) {
  switch (action.type) {

    case actions.RESTAURANTS_REQUEST:
      return { ...state, isLoading: true };

    case actions.RESTAURANTS_SUCCESS:
      return { ...state, isLoading: false, restaurants: action.restaurants, search: action.search };

    case actions.RESTAURANTS_DETAILS_REQUEST:
      return { ...state, isLoading: true };

    case actions.RESTAURANTS_DETAILS_SUCCESS:
      return { ...state, isLoading: false, restaurant: action.restaurant };

    case actions.RESTAURANTS_UPDATE_REQUEST:
      return { ...state, isLoading: true };

    case actions.RESTAURANTS_UPDATE_SUCCESS:
      return { ...state, isLoading: false, restaurant: action.restaurant };

    case actions.RESTAURANTS_UPDATE_IMAGE_REQUEST:
      return { ...state, isLoading: true };

    case actions.RESTAURANTS_UPDATE_IMAGE_SUCCESS:
      return { ...state, isLoading: false, restaurant: action.restaurant };

    case actions.TAGS_GET_SUCCESS:
      return { ...state, allTags: action.tags };

    case actions.RESTAURANTS_TAG_ADD_SUCCESS:
    case actions.RESTAURANTS_TAG_UPDATE_SUCCESS:
    case actions.RESTAURANTS_TAG_MOVE_SUCCESS:
    case actions.RESTAURANTS_TAG_DELETE_SUCCESS:
      return { ...state, restaurant: action.restaurant };

    case actions.RESTAURANTS_UNANSWERED_REQUEST:
      return { ...state, isLoading: true };

    case actions.RESTAURANTS_UNANSWERED_SUCCESS:
      return { ...state, isLoading: false, restaurantsUnanswered: action.restaurants };

    case actions.RESTAURANTS_BILLS_REQUEST:
      return { ...state, isRestaurantBillsLoading: true, hasMoreRestaurantBills: true };

    case actions.RESTAURANTS_BILLS_SUCCESS:
      if (state.restaurant) {
        return { 
          ...state,
          isRestaurantBillsLoading: false,
          hasMoreRestaurantBills: action.bills.length > 0,
          restaurant: {
            ...state.restaurant,
            billList: state.restaurant.billList && action.start > 0
              ? state.restaurant.billList.concat(action.bills)
              : action.bills,
          }
        };
      }
      return { ...state, isLoading: false };

    case billActions.BILL_DELETE_SUCCESS:
      if (state.restaurant && state.restaurant.billList) {
        var bills = state.restaurant.billList;
        var deletedIndex = bills.findIndex(x => x.id === action.deletedBillId);
        if (deletedIndex > -1) {
          bills.splice(deletedIndex, 1);
        }
        return { ...state, restaurant: { ...state.restaurant, billList: bills } };
      }
      return { ...state, restaurant: { ...state.restaurant } };

    case actions.RESTAURANTS_PAYOUTS_REQUEST:
      return { ...state, isRestaurantPayoutsLoading: true, hasMoreRestaurantPayouts: true };

    case actions.RESTAURANTS_PAYOUTS_SUCCESS:
      if (state.restaurant) {
        return { 
          ...state,
          isRestaurantPayoutsLoading: false,
          hasMoreRestaurantPayouts: action.payouts.length > 0,
          restaurant: {
            ...state.restaurant,
            payoutList: state.restaurant.payoutList && action.start > 0
              ? state.restaurant.payoutList.concat(action.payouts)
              : action.payouts,
          }
        };
      }
      return { ...state, isLoading: false };

    case actions.RESTAURANTS_EMPLOYEES_REQUEST:
      return { ...state, isRestaurantEmployeesLoading: true };

    case actions.RESTAURANTS_EMPLOYEES_SUCCESS:
      if (state.restaurant) {
        return { 
          ...state,
          isRestaurantEmployeesLoading: false,
          restaurant: {
            ...state.restaurant,
            employeeList: action.employees
          }
        };
      }
      return { ...state, isLoading: false };

    case actions.RESTAURANTS_OPENING_HOURS_DEFAULT_SUCCESS:
        return { ...state, restaurant: action.restaurant };
      
    case actions.RESTAURANTS_OPENING_HOURS_ADD_SUCCESS:
      return { ...state, restaurant: action.restaurant };

    case actions.RESTAURANTS_OPENING_HOURS_DELETE_SUCCESS:
      return { ...state, restaurant: action.restaurant };

    case actions.RESTAURANTS_OPENING_HOURS_SAVE_REQUEST:
      return { ...state, isLoading: true };

    case actions.RESTAURANTS_OPENING_HOURS_SAVE_SUCCESS:
      return { ...state, isLoading: false, restaurant: action.restaurant };

    case actions.RESTAURANTS_OPENING_HOURS_EXCEPTION_REQUEST:
      return { ...state, isRestaurantOpenHoursExceptionsLoading: true };

    case actions.RESTAURANTS_OPENING_HOURS_EXCEPTION_SUCCESS:
      return { ...state, isRestaurantOpenHoursExceptionsLoading: false, restaurant: action.restaurant };

    case actions.RESTAURANTS_OPENING_HOURS_EXCEPTION_ADD_REQUEST:
      return { ...state, isLoading: true };

    case actions.RESTAURANTS_OPENING_HOURS_EXCEPTION_ADD_SUCCESS:
      return { ...state, isLoading: false, restaurant: action.restaurant };

    case actions.RESTAURANTS_OPENING_HOURS_EXCEPTION_DELETE_REQUEST:
      return { ...state, isLoading: true };

    case actions.RESTAURANTS_OPENING_HOURS_EXCEPTION_DELETE_SUCCESS:
      return { ...state, isLoading: false, restaurant: action.restaurant };

    case actions.RESTAURANTS_ERROR:
      return { ...state, isLoading: false };

    case actions.RESTAURANTS_CLEAR:
      return { ...state, restaurants: [], restaurant: {} };

    default:
      return state;
  }
}
