import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../settings';
import { 
  getTokenHeader, 
  getToken, 
  showNotificationSavedSuccess, 
  showNotificationSavedFailed, 
  parseFloatLocale,
  uploadFile
} from '../../helpers/utility';
import moment from 'moment';

export function* getRestaurants() {
  yield takeEvery(actions.RESTAURANTS_REQUEST, function*({search}) {
    try {
      let url = settings.apiUrl + '/dashboard/restaurants';
      if (search) {
        url += '?q=' + encodeURIComponent(search);
      }

      const response = yield call(fetch, url, {
        headers: getTokenHeader()
      });

      if (response.status !== 200) {
        yield put({ type: actions.RESTAURANTS_ERROR });
        return;
      }

      const responseBody = yield response.json();
      yield put({
        type: actions.RESTAURANTS_SUCCESS,
        restaurants: responseBody,
        search
      });

    } 
    catch (e) {
        yield put({ type: actions.RESTAURANTS_ERROR });
        return;
    }
  });
}

export function* getRestaurantDetails(id) {
  try {
    const response = yield call(fetch, settings.apiUrl + '/dashboard/restaurants/' + id, {
      headers: getTokenHeader()
    });

    if (response.status !== 200) {
      yield put({ type: actions.RESTAURANTS_ERROR });
      return;
    }

    const restaurant = yield response.json();
    return restaurant;
    
  } 
  catch (e) {
      yield put({ type: actions.RESTAURANTS_ERROR });
      return;
  }
}

export function* watchRestaurantDetails() {
  yield takeEvery(actions.RESTAURANTS_DETAILS_REQUEST, function*({id, callback}) {
    var restaurant = yield call(getRestaurantDetails, id);

    if (callback) {
      callback();
    }

    yield put({
      type: actions.RESTAURANTS_DETAILS_SUCCESS,
      restaurant
    });
  });
}

export function* updateRestaurant() {
  yield takeEvery(actions.RESTAURANTS_UPDATE_REQUEST, function*({id, data, intl}) {
    try {

      if (data.latitude && data.longitude) {
        data.location = {
          lat: parseFloat(parseFloatLocale(data.latitude).toFixed(6)),
          lng: parseFloat(parseFloatLocale(data.longitude).toFixed(6))
        }
      }

      if (data.fixed_fee) {
        data.fixed_fee = parseInt(parseFloatLocale(data.fixed_fee) * 100, 10);
      }

      if (data.percent_fee) {
        data.percent_fee = parseFloat((parseFloatLocale(data.percent_fee)/100).toFixed(4));
      }

      if (data.invoice_fee) {
        data.invoice_fee = parseFloat((parseFloatLocale(data.invoice_fee)/100).toFixed(4));
      }

      if (data.fee_starts_at) {
        data.fee_starts_at.set({hour:0,minute:0,second:0,millisecond:0});
        data.fee_starts_at = data.fee_starts_at.toISOString();
      }

      if (data.booking_fee) {
        data.booking_fee = parseInt((parseFloatLocale(data.booking_fee) * 100), 10);
      }
      
      if (data.booking_fee_starts_at) {
        data.booking_fee_starts_at.set({hour:0,minute:0,second:0,millisecond:0});
        data.booking_fee_starts_at = data.booking_fee_starts_at.toISOString();
      }
      
      if (data.monthly_fee) {
        data.monthly_fee = parseInt((parseFloatLocale(data.monthly_fee) * 100), 10);
      }
      
      if (data.monthly_fee_starts_at) {
        data.monthly_fee_starts_at.set({hour:0,minute:0,second:0,millisecond:0});
        data.monthly_fee_starts_at = data.monthly_fee_starts_at.toISOString();
      }
      
      // Non updateable fields
      delete data.slug
      delete data.fingerprint

      const response = yield call(fetch, settings.apiUrl + '/dashboard/restaurants/' + id, {
        method: 'PATCH',
        headers: getTokenHeader(),
        body: JSON.stringify(data)
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('updateRestaurant error'));
      }
  
      const restaurant = yield response.json();
      yield put({
        type: actions.RESTAURANTS_UPDATE_SUCCESS,
        restaurant
      });

      showNotificationSavedSuccess(intl);
    } 
    catch (e) {
      showNotificationSavedFailed(intl);
      yield put({ type: actions.RESTAURANTS_ERROR });
      return;
    }
  });
}

export function* updateRestaurantImage() {
  yield takeEvery(actions.RESTAURANTS_UPDATE_IMAGE_REQUEST, function*({id, imageFile, intl}) {
    try {

      let uploadMediaResponse = yield call(uploadFile, imageFile);
      var data = {
        image_id: uploadMediaResponse.id
      }

      const response = yield call(fetch, settings.apiUrl + '/dashboard/restaurants/' + id, {
        method: 'PATCH',
        headers: getTokenHeader(),
        body: JSON.stringify(data)
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('updateRestaurantImage error'));
      }

      var restaurant = yield response.json();
      yield put({
        type: actions.RESTAURANTS_UPDATE_IMAGE_SUCCESS,
        restaurant
      });

      showNotificationSavedSuccess(intl);
    } 
    catch (e) {
      showNotificationSavedFailed(intl);
      yield put({ type: actions.RESTAURANTS_ERROR });
      return;
    }
  });
}

export function* getTags() {
  yield takeEvery(actions.TAGS_GET_REQUEST, function*() {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/tags', {
        headers: getTokenHeader()
      });

      const tags = yield response.json();
      yield put({
        type: actions.TAGS_GET_SUCCESS,
        tags
      });

    } 
    catch (e) {
        yield put({ type: actions.RESTAURANTS_ERROR });
        return;
    }
  });
}

export function* addRestaurantTag() {
  yield takeEvery(actions.RESTAURANTS_TAG_ADD_REQUEST, function*({restaurantId, tag}) {
    try {
      var restaurant = yield getStateRestaurant(restaurantId);
      if (restaurant) {
        if (restaurant.tags) {
          restaurant.tags.push(tag)
        }
        else {
          restaurant.tags = [tag];
        }

        yield put({
          type: actions.RESTAURANTS_TAG_ADD_SUCCESS,
          restaurant: Object.assign({}, restaurant)
        });
      }
    } 
    catch (e) {
      yield put({ type: actions.RESTAURANTS_ERROR });
      return;
    }
  });
}

export function* updateRestaurantTag() {
  yield takeEvery(actions.RESTAURANTS_TAG_UPDATE_REQUEST, function*({restaurantId, tag}) {
    try {
      var restaurant = yield getStateRestaurant(restaurantId);
      if (restaurant) {
        if (restaurant.tags) {
          let existingTag = restaurant.tags.find(x => x.code === tag.code);
          if (existingTag) {
            existingTag.code = tag.code
            existingTag.translation = tag.translation
          }
        }

        yield put({
          type: actions.RESTAURANTS_TAG_UPDATE_SUCCESS,
          restaurant: Object.assign({}, restaurant)
        });
      }
    } 
    catch (e) {
      yield put({ type: actions.RESTAURANTS_ERROR });
      return;
    }
  });
}

export function* moveRestaurantTag() {
  yield takeEvery(actions.RESTAURANTS_TAG_MOVE_REQUEST, function*({restaurantId, tag, oldPos, newPos}) {
    try {
      var restaurant = yield getStateRestaurant(restaurantId);
      if (restaurant && restaurant.tags) {
        
        const tags = restaurant.tags.slice();
        tags.splice(oldPos, 1);
        tags.splice(newPos, 0, tag);
        restaurant.tags = tags;

        yield put({
          type: actions.RESTAURANTS_TAG_MOVE_SUCCESS,
          restaurant: Object.assign({}, restaurant)
        });
      }
    } 
    catch (e) {
      yield put({ type: actions.RESTAURANTS_ERROR });
      return;
    }
  });
}

export function* deleteRestaurantTag() {
  yield takeEvery(actions.RESTAURANTS_TAG_DELETE_REQUEST, function*({restaurantId, tag}) {
    try {
      var restaurant = yield getStateRestaurant(restaurantId);
      if (restaurant && restaurant.tags) {
        restaurant.tags = restaurant.tags.filter(x => { return x.code !== tag.code });
        
        yield put({
          type: actions.RESTAURANTS_TAG_DELETE_SUCCESS,
          restaurant: Object.assign({}, restaurant)
        });
      }
    } 
    catch (e) {
      yield put({ type: actions.RESTAURANTS_ERROR });
      return;
    }
  });
}

export function* getRestaurantsUnanswered() {
  yield takeEvery(actions.RESTAURANTS_UNANSWERED_REQUEST, function*() {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/conversations/unresolved', {
        headers: getTokenHeader()
      });

      if (response.status !== 200) {
        yield put({ type: actions.RESTAURANTS_ERROR });
        return;
      }

      const responseBody = yield response.json();
      yield put({
        type: actions.RESTAURANTS_UNANSWERED_SUCCESS,
        restaurants: responseBody
      });
    }
    catch (e) {
      yield put({ type: actions.RESTAURANTS_ERROR });
      return;
    }
  });
}

export function* getStateRestaurant(restaurantId) {
  const state = yield select();
  var restaurant = null;
  if (state.Restaurants.restaurant && state.Restaurants.restaurant.id === restaurantId) {
    restaurant = state.Restaurants.restaurant;
  }
  else {
    restaurant = state.Restaurants.restaurants.find(x => x.id === restaurantId);
  }

  return restaurant;
}

export function* getRestaurantBills() {
  yield takeEvery(actions.RESTAURANTS_BILLS_REQUEST, function*({restaurantId, start, count}) {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/restaurants/' + restaurantId + '/bills?start=' + start + '&count=' + count, {
        headers: getTokenHeader()
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('getRestaurantBills error'));
      }

      const bills = yield response.json();
      yield put({
        type: actions.RESTAURANTS_BILLS_SUCCESS,
        bills,
        start
      });
    } 
    catch (e) {
      yield put({ type: actions.RESTAURANTS_ERROR });
      return;
    }
  });
}

export function* getRestaurantPayouts() {
  yield takeEvery(actions.RESTAURANTS_PAYOUTS_REQUEST, function*({restaurantId, start, count}) {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/restaurants/' + restaurantId + '/reports?start=' + start + '&count=' + count, {
        headers: getTokenHeader()
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('getRestaurantPayouts error'));
      }

      const payouts = yield response.json();
      yield put({
        type: actions.RESTAURANTS_PAYOUTS_SUCCESS,
        payouts,
        start
      });
    } 
    catch (e) {
      yield put({ type: actions.RESTAURANTS_ERROR });
      return;
    }
  });
}

export function* getRestaurantPayoutPdf() {
  yield takeEvery(actions.RESTAURANTS_PAYOUT_DOWNLOAD_REQUEST, function*({ reportId, url }) {
    try {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "arraybuffer";
      xhr.onload = function () {
        if (this.status === 200) {
            var blob = new Blob([xhr.response], { type: "application/pdf" });
            var objectUrl = URL.createObjectURL(blob);

            // Download PDF to file
            var link = document.createElement('a');
            link.href = objectUrl;
            link.download = reportId + '.pdf';
            link.dispatchEvent(new MouseEvent('click'));
        }
      };
      xhr.setRequestHeader("Authorization", 'Bearer ' + getToken().get('idToken'));
      xhr.send();
    } catch (e) {
      yield put({ type: actions.RESTAURANTS_ERROR });
      return;
    }
  });
}

export function* getRestaurantEmployees() {
  yield takeEvery(actions.RESTAURANTS_EMPLOYEES_REQUEST, function*({restaurantId}) {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/restaurants/' + restaurantId + '/employees', {
        headers: getTokenHeader()
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('getRestaurantEmployees error'));
      }

      const employees = yield response.json();
      yield put({
        type: actions.RESTAURANTS_EMPLOYEES_SUCCESS,
        employees
      });
    } 
    catch (e) {
      yield put({ type: actions.RESTAURANTS_ERROR });
      return;
    }
  });
}

export function* getRestaurantDefaultOpeningHours() {
  yield takeEvery(actions.RESTAURANTS_OPENING_HOURS_DEFAULT_REQUEST, function*({restaurantId}) {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/restaurants/' + restaurantId + '/hours/default', {
        headers: getTokenHeader()
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('getRestaurantDefaultOpeningHours error'));
      }

      const openHours = yield response.json();
      var restaurant = yield getStateRestaurant(restaurantId);
      if (restaurant) {
        restaurant.open_hours_default = openHours
      }

      yield put({
        type: actions.RESTAURANTS_OPENING_HOURS_DEFAULT_SUCCESS,
        restaurant: Object.assign({}, restaurant)
      });
    } 
    catch (e) {
      yield put({ type: actions.RESTAURANTS_ERROR });
      return;
    }
  });
}

export function* addRestaurantOpenHour() {
  yield takeEvery(actions.RESTAURANTS_OPENING_HOURS_ADD_REQUEST, function*({restaurantId, day, from, to}) {
    try {

      var restaurant = yield getStateRestaurant(restaurantId);
      if (restaurant) {
        var openHourDay = restaurant.open_hours_default.find(x => x.day === day);
        openHourDay.hours.push({
          from,
          to
        })

        openHourDay.hours.sort((a, b) => (a.from > b.from) ? 1 : -1)
      }

      yield put({
        type: actions.RESTAURANTS_OPENING_HOURS_ADD_SUCCESS,
        restaurant: Object.assign({}, restaurant)
      });
    } 
    catch (e) {
      yield put({ type: actions.RESTAURANTS_ERROR });
      return;
    }
  });
}

export function* deleteRestaurantOpenHour() {
  yield takeEvery(actions.RESTAURANTS_OPENING_HOURS_DELETE_REQUEST, function*({restaurantId, day, from, to}) {
    try {

      var restaurant = yield getStateRestaurant(restaurantId);
      if (restaurant) {
        var openHourDay = restaurant.open_hours_default.find(x => x.day === day);
        openHourDay.hours = openHourDay.hours.filter(x => x.from !== from && x.to !== to);
      }

      yield put({
        type: actions.RESTAURANTS_OPENING_HOURS_DELETE_SUCCESS,
        restaurant: Object.assign({}, restaurant)
      });
    } 
    catch (e) {
      yield put({ type: actions.RESTAURANTS_ERROR });
      return;
    }
  });
}

export function* saveRestaurantOpenHours() {
  yield takeEvery(actions.RESTAURANTS_OPENING_HOURS_SAVE_REQUEST, function*({restaurantId, intl}) {
    try {

      var restaurant = yield getStateRestaurant(restaurantId);
      if (restaurant) {
        const response = yield call(fetch, settings.apiUrl + '/dashboard/restaurants/' + restaurantId + '/hours/default', {
          method: 'PATCH',
          headers: getTokenHeader(),
          body: JSON.stringify(restaurant.open_hours_default)
        });

        if (response.status !== 200) {
          throw Object.assign(new Error('saveRestaurantOpenHours error'));
        }

        // Fetch new restaurant details for the sake of new open hours
        const updateOpenHours = yield response.json();
        restaurant = yield call(getRestaurantDetails, restaurantId);
        restaurant.open_hours_default = updateOpenHours;

        yield put({
          type: actions.RESTAURANTS_OPENING_HOURS_SAVE_SUCCESS,
          restaurant: Object.assign({}, restaurant)
        });

        showNotificationSavedSuccess(intl);
      }
      else {
        yield put({ type: actions.RESTAURANTS_ERROR });
      }
    } 
    catch (e) {
      showNotificationSavedFailed(intl);
      yield put({ type: actions.RESTAURANTS_ERROR });
      return;
    }
  });
}

export function* getRestaurantOpeningHourExceptions(restaurantId) {
  try {
    var date_start = moment().format("YYYY-MM-DD");
    var date_end = moment().add(1, 'years').format("YYYY-MM-DD");
    var url = '/dashboard/restaurants/' + restaurantId + '/hours/exceptions?date_start=' + date_start + '&date_end=' + date_end + '&filter=admin';

    const response = yield call(fetch, settings.apiUrl + url, {
      headers: getTokenHeader()
    });

    if (response.status !== 200) {
      throw Object.assign(new Error('getRestaurantDefaultOpeningHours error'));
    }

    const exceptionHours = yield response.json();
    return exceptionHours;
  } 
  catch (e) {
    yield put({ type: actions.RESTAURANTS_ERROR });
    return null;
  }
}

export function* watchRestaurantOpeningHourExceptions() {
  yield takeEvery(actions.RESTAURANTS_OPENING_HOURS_EXCEPTION_REQUEST, function*({restaurantId}) {
    var exceptionHours = yield call(getRestaurantOpeningHourExceptions, restaurantId);
    if (exceptionHours) {

      var restaurant = yield getStateRestaurant(restaurantId);
      if (restaurant) {
        restaurant.open_hours_exceptions = exceptionHours
      }

      yield put({
        type: actions.RESTAURANTS_OPENING_HOURS_EXCEPTION_SUCCESS,
        restaurant: Object.assign({}, restaurant)
      });

    }
  });
}

export function* addRestaurantOpenHourException() {
  yield takeEvery(actions.RESTAURANTS_OPENING_HOURS_EXCEPTION_ADD_REQUEST, function*({restaurantId, fromDate, toDate, isClosedHours, intl}) {
    try {

      var restaurant = yield getStateRestaurant(restaurantId);
      if (restaurant) {

        var endpoint = '/dashboard/restaurants/' + restaurantId + '/hours';
        var data;

        if (!isClosedHours) {

          // Create open hours exception
          endpoint += '/exceptions'; 
          data = {
            dates: [{
              date_start: fromDate.format("YYYY-MM-DD"),
              date_end: toDate.format("YYYY-MM-DD")
            }],
            hours: [{
              from: fromDate.format("HH:mm"),
              to: toDate.format("HH:mm")
            }]
          }
        }
        else {

          // Create closed hours, converted to open hours on backend
          endpoint += '/close'; 
          data = {
            time_start: fromDate.format(),
            time_end: toDate.format()
          }

        }

        const response = yield call(fetch, settings.apiUrl + endpoint, {
          method: 'POST',
          headers: getTokenHeader(),
          body: JSON.stringify(data)
        });

        if (response.status !== 200) {
          throw Object.assign(new Error('addRestaurantOpenHourException error'));
        }

        // If exception dates are in the current week, fetch new restaurant details for the sake of potential new open hours
        var today = moment();
        var nextWeek = moment().add(1, 'weeks');
        if ((fromDate.isAfter(today) && fromDate.isBefore(nextWeek)) || (toDate.isAfter(today) && toDate.isBefore(nextWeek))) {
          var defaultHours = restaurant.open_hours_default;
          restaurant = yield call(getRestaurantDetails, restaurantId);
          restaurant.open_hours_default = defaultHours;
        }

        // Fetch new exception details
        var exceptionHours = yield call(getRestaurantOpeningHourExceptions, restaurantId);
        restaurant.open_hours_exceptions = exceptionHours;

        yield put({
          type: actions.RESTAURANTS_OPENING_HOURS_EXCEPTION_ADD_SUCCESS,
          restaurant: Object.assign({}, restaurant)
        });

        showNotificationSavedSuccess(intl);
      }
      else {
        throw Object.assign(new Error('addRestaurantOpenHourException error'));
      }
    } 
    catch (e) {
      showNotificationSavedFailed(intl);
      yield put({ type: actions.RESTAURANTS_ERROR });
      return;
    }
  });
}

export function* deleteRestaurantOpenHourException() {
  yield takeEvery(actions.RESTAURANTS_OPENING_HOURS_EXCEPTION_DELETE_REQUEST, function*({restaurantId, fromDate, toDate, intl}) {
    try {

      var restaurant = yield getStateRestaurant(restaurantId);
      if (restaurant) {

        var endpoint = '/dashboard/restaurants/' + restaurantId + '/hours/exceptions';
        var data = {
          dates: [{
            date_start: fromDate.format("YYYY-MM-DD"),
            date_end: toDate.format("YYYY-MM-DD")
          }]
        }

        const response = yield call(fetch, settings.apiUrl + endpoint, {
          method: 'DELETE',
          headers: getTokenHeader(),
          body: JSON.stringify(data)
        });

        if (response.status !== 204) {
          throw Object.assign(new Error('deleteRestaurantOpenHourException error'));
        }

        // If exception dates are in the current week, fetch new restaurant details for the sake of potential new open hours
        var today = moment();
        var nextWeek = moment().add(1, 'weeks');
        if ((fromDate.isAfter(today) && fromDate.isBefore(nextWeek)) || (toDate.isAfter(today) && toDate.isBefore(nextWeek))) {
          var defaultHours = restaurant.open_hours_default;
          restaurant = yield call(getRestaurantDetails, restaurantId);
          restaurant.open_hours_default = defaultHours;
        }

        // Fetch new exception details
        var exceptionHours = yield call(getRestaurantOpeningHourExceptions, restaurantId);
        restaurant.open_hours_exceptions = exceptionHours;

        yield put({
          type: actions.RESTAURANTS_OPENING_HOURS_EXCEPTION_DELETE_SUCCESS,
          restaurant: Object.assign({}, restaurant)
        });

        showNotificationSavedSuccess(intl);
      }
      else {
        throw Object.assign(new Error('deleteRestaurantOpenHourException error'));
      }
    } 
    catch (e) {
      showNotificationSavedFailed(intl);
      yield put({ type: actions.RESTAURANTS_ERROR });
      return;
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getRestaurants),
    fork(watchRestaurantDetails),
    fork(updateRestaurant),
    fork(updateRestaurantImage),
    fork(getTags),
    fork(addRestaurantTag),
    fork(updateRestaurantTag),
    fork(moveRestaurantTag),
    fork(deleteRestaurantTag),
    fork(getRestaurantsUnanswered),
    fork(getRestaurantBills),
    fork(getRestaurantPayouts),
    fork(getRestaurantPayoutPdf),
    fork(getRestaurantEmployees),
    fork(getRestaurantDefaultOpeningHours),
    fork(addRestaurantOpenHour),
    fork(deleteRestaurantOpenHour),
    fork(saveRestaurantOpenHours),
    fork(watchRestaurantOpeningHourExceptions),
    fork(addRestaurantOpenHourException),
    fork(deleteRestaurantOpenHourException)
  ]);
}
