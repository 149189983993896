const actions = {
  USERS_REQUEST: 'USERS_REQUEST',
  USERS_SUCCESS: 'USERS_SUCCESS',
  USERS_DETAILS_REQUEST: 'USERS_DETAILS_REQUEST',
  USERS_DETAILS_SUCCESS: 'USERS_DETAILS_SUCCESS',
  USERS_UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
  USERS_UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
  USERS_DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  USERS_DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  USERS_LOYALTY_LEVELS_REQUEST: 'USERS_LOYALTY_LEVELS_REQUEST',
  USERS_LOYALTY_LEVELS_SUCCESS: 'USERS_LOYALTY_LEVELS_SUCCESS',
  USERS_UPDATE_LOYALTY_REQUEST: 'USERS_UPDATE_LOYALTY_REQUEST',
  USERS_UPDATE_LOYALTY_SUCCESS: 'USERS_UPDATE_LOYALTY_SUCCESS',
  USERS_ADD_CREDITS_REQUEST: 'USERS_ADD_CREDITS_REQUEST',
  USERS_ADD_CREDITS_SUCCESS: 'USERS_ADD_CREDITS_SUCCESS',
  USERS_UPDATE_CREDITS_REQUEST: 'USERS_UPDATE_CREDITS_REQUEST',
  USERS_UPDATE_CREDITS_SUCCESS: 'USERS_UPDATE_CREDITS_SUCCESS',
  USERS_BOOKINGS_REQUEST: 'USERS_BOOKINGS_REQUEST',
  USERS_BOOKINGS_SUCCESS: 'USERS_BOOKINGS_SUCCESS',
  USERS_BILLS_REQUEST: 'USERS_BILLS_REQUEST',
  USERS_BILLS_SUCCESS: 'USERS_BILLS_SUCCESS',
  USERS_PAYMENT_OPTIONS_REQUEST: 'USERS_PAYMENT_OPTIONS_REQUEST',
  USERS_PAYMENT_OPTIONS_SUCCESS: 'USERS_PAYMENT_OPTIONS_SUCCESS',
  USERS_ERROR: 'USERS_ERROR',
  USERS_CLEAR: 'USERS_CLEAR',
  getUsers: (start, count, filter, search) => ({
    type: actions.USERS_REQUEST,
    start,
    count,
    filter,
    search
  }),
  getUserDetails: (id, callback) => ({
    type: actions.USERS_DETAILS_REQUEST,
    id,
    callback
  }),
  updateUser: (id, data, intl) => ({
    type: actions.USERS_UPDATE_REQUEST,
    id,
    data,
    intl
  }),
  deleteUser: (id, intl) => ({
    type: actions.USERS_DELETE_REQUEST,
    id,
    intl
  }),
  getLoyaltyLevels: () => ({
    type: actions.USERS_LOYALTY_LEVELS_REQUEST
  }),
  updateLoyalty: (id, data, intl) => ({
    type: actions.USERS_UPDATE_LOYALTY_REQUEST,
    id,
    data,
    intl
  }),
  addCreditsToUser: (id, credits, note, intl) => ({
    type: actions.USERS_ADD_CREDITS_REQUEST,
    id,
    credits,
    note,
    intl
  }),
  updateUserCredit: (userId, creditId, data, intl) => ({
    type: actions.USERS_UPDATE_CREDITS_REQUEST,
    userId,
    creditId,
    data,
    intl
  }),
  getUserBookings: (userId, start, count) => ({
    type: actions.USERS_BOOKINGS_REQUEST,
    userId,
    start,
    count
  }),
  getUserBills: (userId, start, count) => ({
    type: actions.USERS_BILLS_REQUEST,
    userId,
    start,
    count
  }),
  getUserPaymentOptions: (userId) => ({
    type: actions.USERS_PAYMENT_OPTIONS_REQUEST,
    userId
  }),
  clearUsers: () => ({
    type: actions.USERS_CLEAR
  }),
};
export default actions;
