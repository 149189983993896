import antdSv from 'antd/lib/locale-provider/sv_SE';
import appLocaleData from 'react-intl/locale-data/sv';
import svMessages from '../locales/sv_SE.json';
// import { getKeys, getValues } from '../conversion';
// getValues(enMessages);

const SvLang = {
  messages: {
    ...svMessages,
  },
  antd: antdSv,
  locale: 'sv-SE',
  data: appLocaleData,
};
export default SvLang;
