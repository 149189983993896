const TOPUP_TYPE_CARD = 'card';
const TOPUP_TYPE_SEPA = 'sepa';

const IBAN_STYLE = {
  base: {
    color: '#32325d',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    },
    ':-webkit-autofill': {
      color: '#32325d',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
    ':-webkit-autofill': {
      color: '#fa755a',
    },
  }
};

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ['SEPA'],
  // Elements can use a placeholder as an example IBAN that reflects
  // the IBAN format of your customer's country. If you know your
  // customer's country, we recommend that you pass it to the Element as the
  // placeholderCountry.
  placeholderCountry: 'SE',
  style: IBAN_STYLE,
};

const constants = {
  TOPUP_TYPE_CARD,
  TOPUP_TYPE_SEPA,
  IBAN_STYLE,
  IBAN_ELEMENT_OPTIONS
};

export default constants;
