import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../settings';
import { getTokenHeader, getToken, showNotificationSavedSuccess, showNotificationSavedFailed } from '../../helpers/utility';

export function* getGiftcards() {
  yield takeEvery(actions.GIFTCARDS_REQUEST, function*({start, count}) {
    try {

      let url = settings.apiUrl + '/dashboard/gift-cards?' +
      'start=' + start +
      '&count=' + count;

      const response = yield call(fetch, url, {
        headers: getTokenHeader()
      });

      if (response.status !== 200) {
        throw Object.assign(new Error('getGiftcards error'));
      }

      const responseBody = yield response.json();
      yield put({
        type: actions.GIFTCARDS_SUCCESS,
        giftcards: responseBody,
        start
      });

    } 
    catch (e) {
        yield put({ type: actions.GIFTCARDS_ERROR });
        return;
    }
  });
}

export function* createGiftCard() {
  yield takeEvery(actions.GIFTCARDS_CREATE_REQUEST, function*({data, form, intl}) {
    try {

      let body = getGiftcardRequestBody(data);

      // Move date to first second of next day
      if (body.expires_at) {
        body.expires_at = body.expires_at.add(1, "days").startOf("day").format();
      }

      const response = yield call(fetch, settings.apiUrl + '/dashboard/gift-cards', {
        method: 'POST',
        headers: getTokenHeader(),
        body: JSON.stringify(body)
      });

      if (response.status !== 200) {
        throw Object.assign(new Error('createGiftCard error'));
      }

      const responseBody = yield response.json();
      yield put({
        type: actions.GIFTCARDS_CREATE_SUCCESS,
        giftcard: responseBody
      });

      showNotificationSavedSuccess(intl);
      form.resetFields();
    } 
    catch (e) {
        yield put({ type: actions.GIFTCARDS_ERROR });
        showNotificationSavedFailed(intl);
        return;
    }
  });
}

export function* getGiftcardTemplates() {
  yield takeEvery(actions.GIFTCARDS_TEMPLATES_REQUEST, function*() {
    try {

      const response = yield call(fetch, settings.apiUrl + '/dashboard/gift-card/templates', {
        headers: getTokenHeader()
      });

      if (response.status !== 200) {
        throw Object.assign(new Error('getGiftcardTemplates error'));
      }

      const responseBody = yield response.json();
      yield put({
        type: actions.GIFTCARDS_TEMPLATES_SUCCESS,
        templates: responseBody
      });

    } 
    catch (e) {
        yield put({ type: actions.GIFTCARDS_ERROR });
        return;
    }
  });
}

export function* getGiftcardDownload() {
  yield takeEvery(actions.GIFTCARDS_DOWNLOAD_REQUEST, function*({ giftcardId, downloadUrl }) {
    try {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", downloadUrl);
      xhr.responseType = "arraybuffer";
      xhr.onload = function () {
        if (this.status === 200) {
            console.log();
            var blob = new Blob([xhr.response], { type: "octet/stream" });
            var objectUrl = URL.createObjectURL(blob);

            // Determine file type
            var contentType = this.getResponseHeader('content-type');
            var fileType = '.unknown';
            if (contentType.indexOf('zip') > -1) {
              fileType = '.zip';
            }
            else if (contentType.indexOf('pdf') > -1) {
              fileType = '.pdf';
            }

            var link = document.createElement('a');
            link.href = objectUrl;
            link.download = 'Giftcard-' + giftcardId + fileType;
            link.dispatchEvent(new MouseEvent('click'));
        }
      };
      xhr.setRequestHeader("Authorization", 'Bearer ' + getToken().get('idToken'));
      xhr.send();
    } catch (e) {
      yield put({ type: actions.GIFTCARDS_ERROR });
      return;
    }
  });
}

function getGiftcardRequestBody(data) {
  let body = {
    amount: parseInt(data.amount * 100, 10),
    count: parseInt(data.count, 10),
    expires_at: data.expires_at ? data.expires_at.utc() : null,
    template_id: data.template,
    format: data.format || null,
    note: data.note || null
  };
  
  if (data.restaurants && data.restaurants.length > 0) {
    body.restaurant_ids = data.restaurants;
  }

  return body;
}

export default function* rootSaga() {
  yield all([
    fork(getGiftcards),
    fork(createGiftCard),
    fork(getGiftcardTemplates),
    fork(getGiftcardDownload)
  ]);
}
