import actions from "./actions";
import billActions from "../bills/actions";

const initState = {
  users: [],
  user: {},
  loyaltyLevels: [],
  hasMoreUsers: true,
  hasMoreUserBookings: true,
  hasMoreUserBills: true
};

export default function overviewReducer(state = initState, action) {

  switch (action.type) {
    case actions.USERS_REQUEST:
      var requestState = {
        ...state,
        hasMoreUsers: true,
        isLoading: true
      };

      // If new filter or other search, clean data
      if (state.filter !== action.filter || state.search !== action.search) {
        requestState.users = [];
      }

      return requestState;

    case actions.USERS_SUCCESS:
      return {
        ...state,
        hasMoreUsers: action.users.length > 0,
        isLoading: false,
        users:
          state.users && action.start > 0
            ? state.users.concat(action.users)
            : action.users,
        filter: action.filter,
        search: action.search
      };

    case actions.USERS_DETAILS_REQUEST:
      return { ...state, isLoading: true };

    case actions.USERS_DETAILS_SUCCESS:
      return { ...state, isLoading: false, user: action.user };

    case actions.USERS_UPDATE_REQUEST:
      return { ...state, isLoading: true };

    case actions.USERS_UPDATE_SUCCESS:
      return { ...state, isLoading: false, user: action.user };

    case actions.USERS_DELETE_REQUEST:
      return { ...state, isLoading: true };

    case actions.USERS_DELETE_SUCCESS:
      return { ...state, isLoading: false, user: action.user };

    case actions.USERS_LOYALTY_LEVELS_SUCCESS:
      return { ...state, loyaltyLevels: action.loyaltyLevels };

    case actions.USERS_UPDATE_LOYALTY_REQUEST:
      return { ...state, isLoading: true };

    case actions.USERS_UPDATE_LOYALTY_SUCCESS:
      return { ...state, isLoading: false, user: action.user };

    case actions.USERS_ADD_CREDITS_REQUEST:
      return { ...state, isLoading: true };

    case actions.USERS_ADD_CREDITS_SUCCESS:
      return { ...state, isLoading: false, user: action.user };

    case actions.USERS_UPDATE_CREDITS_REQUEST:
      return { ...state, isLoading: true };

    case actions.USERS_UPDATE_CREDITS_SUCCESS:
      return { ...state, isLoading: false, user: action.user };

    case actions.USERS_BOOKINGS_REQUEST:
      return { 
        ...state,
        isUserBookingsLoading: true,
        hasMoreUserBookings: true
      };

    case actions.USERS_BOOKINGS_SUCCESS:
      if (state.user) {
        return { 
            ...state,
            isUserBookingsLoading: false,
            hasMoreUserBookings: action.bookings.length > 0,
            user: {
              ...state.user,
              bookingList: state.user.bookingList && action.start > 0
                ? state.user.bookingList.concat(action.bookings)
                : action.bookings,
            }
          };
      }
      return { ...state, isLoading: false };

    case actions.USERS_BILLS_REQUEST:
      return { 
        ...state,
        isUserBillsLoading: true,
        hasMoreUserBills: true
      };

    case actions.USERS_BILLS_SUCCESS:
      if (state.user) {
        return { 
            ...state,
            isUserBillsLoading: false,
            hasMoreUserBills: action.bills.length > 0,
            user: {
              ...state.user,
              billList: state.user.billList && action.start > 0
                ? state.user.billList.concat(action.bills)
                : action.bills,
            }
          };
      }
      return { ...state, isLoading: false };

    case billActions.BILL_DELETE_SUCCESS:
      if (state.user && state.user.billList) {
        var bills = state.user.billList;
        var deletedIndex = bills.findIndex(x => x.id === action.deletedBillId);
        if (deletedIndex > -1) {
          bills.splice(deletedIndex, 1);
        }
        return { ...state, user: { ...state.user, billList: bills } };
      }
      return { ...state, user: { ...state.user } };

    case actions.USERS_PAYMENT_OPTIONS_REQUEST:
      return { ...state, isUserPaymentOptionsLoading: true };

    case actions.USERS_PAYMENT_OPTIONS_SUCCESS:
      if (state.user) {
        return { 
            ...state,
            isUserPaymentOptionsLoading: false,
            user: {
              ...state.user,
              paymentOptions: action.paymentOptions
            }
          };
      }
      return { ...state, isUserPaymentOptionsLoading: false };

    case actions.USERS_CLEAR:
      return { ...state, users: [], user: {}, loyaltyLevels: [] };

    default:
      return state;
  }
}
