import { all, takeEvery, put, fork, call, select } from "redux-saga/effects";
import actions from "./actions";
import settings from "../../settings";
import { getTokenHeader, getToken, showNotificationSuccess, showNotificationFailed, showNotificationSavedSuccess, showNotificationSavedFailed, showNotificationError } from "../../helpers/utility";
import moment from "moment";

export function* getBills() {
  yield takeEvery(actions.BILLS_REQUEST, function*({
    start,
    count,
    filter,
    search,
    startTime,
    endTime
  }) {
    try {
      let url =
        settings.apiUrl +
        "/dashboard/bills?" +
        "start=" +
        start +
        "&count=" +
        count +
        "&filter=" +
        (filter || "all");

      if (search) {
        url += "&q=" + encodeURIComponent(search);
      }

      if (startTime) {
        url += "&start_date=" + encodeURIComponent(startTime.format())
      }

      if (endTime) {
        url += "&end_date=" + encodeURIComponent(endTime.format())
      }

      const response = yield call(fetch, url, {
        headers: getTokenHeader()
      });

      if (response.status !== 200) {
        yield put({ type: actions.BILLS_ERROR });
        return;
      }

      const responseBody = yield response.json();
      yield put({
        type: actions.BILLS_SUCCESS,
        bills: responseBody,
        filter,
        search,
        start,
        startTime,
        endTime
      });
    } catch (e) {
      yield put({ type: actions.BILLS_ERROR });
      return;
    }
  });
}

export function* getBillDetails(id) {
  try {
    const response = yield call(
      fetch,
      settings.apiUrl + "/dashboard/bills/" + id,
      {
        headers: getTokenHeader()
      }
    );

    if (response.status !== 200) {
      yield put({ type: actions.BILLS_ERROR });
      return;
    }

    const bill = yield response.json();
    yield put({
      type: actions.BILL_DETAILS_SUCCESS,
      bill
    });

    return bill;
  } catch (e) {
    yield put({ type: actions.BILLS_ERROR });
    return;
  }
}

function* watchBillDetails() {
  yield takeEvery(actions.BILL_DETAILS_REQUEST, function*({ id, callback }) {
    let bill = yield call(getBillDetails, id);
    if (callback) {
      callback(bill);
    }
  });
}

export function* manageBill() {
  yield takeEvery(actions.BILL_MANAGE_REQUEST, function*({id, data, intl}) {
    try {

      let body = {};

      if (data.manage_promo) {
        body.promo = data.manage_promo;
      }

      if (data.manage_credits_used && data.manage_credits_used !== 0) {
        body.credits_used = data.manage_credits_used === 1;
      }

      if (data.manage_payment_option) {
        body.payment_option = data.manage_payment_option;
      }

      if (data.manage_tips) {
        body.tips = parseInt(data.manage_tips, 10);
      }

      if (data.manage_payers) {
        body.payers = data.manage_payers.map(user_id => { return { user_id } })
      }

      const response = yield call(fetch, settings.apiUrl + '/dashboard/bills/' + id + '/manage', {
        method: 'POST',
        headers: getTokenHeader(),
        body: JSON.stringify(body)
      });
  
      if (response.status !== 200) {
        let errorResponse = yield response.json();
        throw Object.assign(new Error(errorResponse.error ? errorResponse.error.message : null));
      }

      let bill = yield call(getBillDetails, id);
      yield put({
        type: actions.BILL_MANAGE_SUCCESS,
        bill
      });

      showNotificationSavedSuccess(intl);
    } 
    catch (e) {
      if (e.message) {
        showNotificationError(e.message);
      }
      else {
        showNotificationSavedFailed(intl);
      }
      
      yield put({ type: actions.BILLS_ERROR });
      return;
    }
  });
}

export function* deleteBill() {
  yield takeEvery(actions.BILL_DELETE_REQUEST, function*({ id }) {
    try {
      const response = yield call(
        fetch,
        settings.apiUrl + "/dashboard/bills/" + id,
        {
          method: "DELETE",
          headers: getTokenHeader()
        }
      );

      if (response.status !== 200) {
        yield put({ type: actions.BILLS_ERROR });
        return;
      }

      yield put({
        type: actions.BILL_DELETE_SUCCESS,
        deletedBillId: id
      });
    } catch (e) {
      yield put({ type: actions.BILLS_ERROR });
      return;
    }
  });
}

export function* getRecoveryBillDetails() {
  yield takeEvery(actions.BILL_RECOVERY_DETAILS_REQUEST, function*({ billId }) {
    try {
      const bill = yield call(getBillDetails, billId);

      const orderResponse = yield call(
        fetch,
        settings.apiUrl + "/dashboard/bills/" + billId + "/orders",
        {
          headers: getTokenHeader()
        }
      );

      if (orderResponse.status !== 200) {
        yield put({ type: actions.BILLS_ERROR });
        return;
      }

      const orderResponseBody = yield orderResponse.json();
      yield put({
        type: actions.BILL_RECOVERY_DETAILS_SUCCESS,
        recoveryBillOrders: orderResponseBody
      });

      // Filter orders by bill time
      yield call(
        searchRecoveryBillOrders,
        null,
        null,
        moment(bill.created_at),
        null
      );
    } catch (e) {
      yield put({ type: actions.BILLS_ERROR });
      return;
    }
  });
}

export function* searchRecoveryBillOrders(
  tableNumber,
  amount,
  startTime,
  endTime
) {
  try {
    const state = yield select();
    const orders = state.Bills.recoveryBillOrders;

    let filteredOrders = null;
    if (state.Bills.recoveryBillOrders) {
      if (tableNumber) {
        filteredOrders = orders.filter(x => x.table_nr === tableNumber);
      }

      if (amount) {
        amount = parseFloat(amount);
        filteredOrders = filteredOrders
          ? filteredOrders.filter(
              x => x.total === amount || x.total / 100 === amount
            )
          : orders.filter(x => x.total === amount || x.total / 100 === amount);
      }

      if (startTime) {
        filteredOrders = filteredOrders
          ? filteredOrders.filter(x => moment(x.date) >= startTime)
          : orders.filter(x => moment(x.date) >= startTime);
      }

      if (endTime) {
        filteredOrders = filteredOrders
          ? filteredOrders.filter(x => moment(x.date) <= endTime)
          : orders.filter(x => moment(x.date) <= endTime);
      }
    }

    yield put({
      type: actions.BILL_RECOVERY_SEARCH_SUCCESS,
      filteredRecoveryBillOrders: filteredOrders
    });
  } catch (e) {
    yield put({ type: actions.BILLS_ERROR });
    return;
  }
}

function* watchSearchRecoveryBillOrders() {
  yield takeEvery(actions.BILL_RECOVERY_SEARCH_REQUEST, function*({
    tableNumber,
    amount,
    startTime,
    endTime
  }) {
    yield call(
      searchRecoveryBillOrders,
      tableNumber,
      amount,
      startTime,
      endTime
    );
  });
}

export function* recoverBill() {
  yield takeEvery(actions.BILL_RECOVERY_PROCESS_REQUEST, function*({
    billId,
    orderId
  }) {
    try {
      const response = yield call(
        fetch,
        settings.apiUrl + "/dashboard/bills/" + billId + "/orders/" + orderId,
        {
          method: "POST",
          headers: getTokenHeader()
        }
      );

      if (response.status !== 200) {
        yield put({ type: actions.BILL_RECOVERY_PROCESS_ERROR });
        return;
      }

      yield put({
        type: actions.BILL_RECOVERY_PROCESS_SUCCESS
      });
    } catch (e) {
      yield put({ type: actions.BILL_RECOVERY_PROCESS_ERROR });
      return;
    }
  });
}

export function* getBillReceipt() {
  yield takeEvery(actions.BILL_RECEIPT_DOWNLOAD_REQUEST, function*({ billId }) {
    try {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", settings.apiUrl + "/dashboard/bills/" + billId + "/receipt");
      xhr.responseType = "arraybuffer";
      xhr.onload = function () {
        if (this.status === 200) {
            var blob = new Blob([xhr.response], { type: "application/pdf" });
            var objectUrl = URL.createObjectURL(blob);

            // Download PDF to file
            var link = document.createElement('a');
            link.href = objectUrl;
            link.download = billId + '.pdf';
            link.dispatchEvent(new MouseEvent('click'));
        }
      };
      xhr.setRequestHeader("Authorization", 'Bearer ' + getToken().get('idToken'));
      xhr.send();
    } catch (e) {
      yield put({ type: actions.BILLS_ERROR });
      return;
    }
  });
}

export function* payBill() {
  yield takeEvery(actions.BILL_PAY_REQUEST, function*({billId, intl}) {
    try {
      const response = yield call(
        fetch,
        settings.apiUrl + "/dashboard/bills/" + billId + "/pay",
        {
          method: "POST",
          headers: getTokenHeader()
        }
      );

      if (response.status !== 200) {
        throw Object.assign(new Error('payBill error'));
      }

      yield put({ type: actions.BILL_PAY_SUCCESS });
      showNotificationSuccess(intl, 'bills.pay_success');

    } catch (e) {
      showNotificationFailed(intl, 'bills.pay_failed');
      yield put({ type: actions.BILLS_ERROR });
      return;
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getBills),
    fork(watchBillDetails),
    fork(manageBill),
    fork(deleteBill),
    fork(getRecoveryBillDetails),
    fork(watchSearchRecoveryBillOrders),
    fork(recoverBill),
    fork(getBillReceipt),
    fork(payBill)
  ]);
}
