import actions from './actions';

const initState = { 
  summary: {},
  cards: [],
  restaurants: [],
  invoiceBills: [],
  smsReports: []
};

export default function overviewReducer(state = initState, action) {
  switch (action.type) {

    case actions.FINANCE_SUMMARY_SUCCESS:
      return { ...state, summary: action.summary };

    case actions.FINANCE_TOPUP_CARDS_SUCCESS:
      return { ...state, cards: action.cards };

    case actions.FINANCE_TOPUP_REQUEST:
      return { ...state, isLoading: true };

    case actions.FINANCE_TOPUP_SUCCESS:
      return { ...state, isLoading: false };

    case actions.FINANCE_REPORT_RESTAURANTS_REQUEST:
      return { ...state, isLoading: true };

    case actions.FINANCE_REPORT_RESTAURANTS_SUCCESS:
      return { ...state, isLoading: false, restaurants: action.restaurants };

    case actions.SMS_REPORT_REQUEST:
      return { ...state, isLoading: true };

    case actions.SMS_REPORT_SUCCESS:
      return { ...state, isLoading: false, smsReports: action.restaurants };

    case actions.SMS_REPORT_DOWNLOAD_REQUEST:
      return { ...state, isReportDownloadLoading: true };
  
    case actions.SMS_REPORT_DOWNLOAD_SUCCESS:
      return { ...state, isReportDownloadLoading: false };

    case actions.FINANCE_REPORT_DOWNLOAD_REQUEST:
      return { ...state, isReportDownloadLoading: true };
  
    case actions.FINANCE_REPORT_DOWNLOAD_SUCCESS:
      return { ...state, isReportDownloadLoading: false };

    case actions.FINANCE_ADVANCED_REPORT_DOWNLOAD_REQUEST:
      return { ...state, isReportDownloadLoading: true };
  
    case actions.FINANCE_ADVANCED_REPORT_DOWNLOAD_SUCCESS:
      return { ...state, isReportDownloadLoading: false };

    case actions.FINANCE_INVOICE_REPORT_BILLS_REQUEST:
      return { ...state, isLoading: true };

    case actions.FINANCE_INVOICE_REPORT_BILLS_SUCCESS:
      return { ...state, isLoading: false, invoiceBills: action.invoiceBills };

    case actions.FINANCE_INVOICE_REPORT_DOWNLOAD_REQUEST:
      return { ...state, isReportDownloadLoading: true };
  
    case actions.FINANCE_INVOICE_REPORT_DOWNLOAD_SUCCESS:
      return { ...state, isReportDownloadLoading: false };

    case actions.FINANCE_CLEAR:
      return { ...state, cards: [], restaurants: [], invoiceBills: [] };

    case actions.FINANCE_ERROR:
      return { ...state, isLoading: false, isReportDownloadLoading: false };

    default:
      return state;
  }
}
