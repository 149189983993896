import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../settings';
import { getTokenHeader} from '../../helpers/utility';

export function* getBookings(start, count, search, startTime, endTime) {
  try {

    let url = settings.apiUrl + '/dashboard/bookings?start=' + start + '&count=' + count;

    if (search) {
      url += "&q=" + encodeURIComponent(search);
    }

    if (startTime) {
      url += "&start_date=" + encodeURIComponent(startTime.format())
    }

    if (endTime) {
      url += "&end_date=" + encodeURIComponent(endTime.format())
    }

    const response = yield call(fetch, url, {
      headers: getTokenHeader()
    });

    if (response.status !== 200) {
      throw Object.assign(new Error('getBookings error'));
    }

    const responseBody = yield response.json();
    yield put({
      type: actions.BOOKINGS_SUCCESS,
      bookings: responseBody,
      search,
      start,
      startTime,
      endTime
    });
  } 
  catch (e) {
    yield put({ type: actions.BOOKINGS_ERROR });
    return;
  }
}

function* watchGetBookings() {
  yield takeEvery(actions.BOOKINGS_REQUEST, function*({start, count, search, startTime, endTime}) {
    yield call(getBookings, start, count, search, startTime, endTime);
  });
}

export default function* rootSaga() {
  yield all([
    fork(watchGetBookings)
  ]);
}
