import Auth from './auth/reducer';
import App from './app/reducer';
import Overview from './overview/reducer';
import Restaurants from './restaurants/reducer';
import Collections from './collections/reducer';
import Bookings from './bookings/reducer';
import Bills from './bills/reducer';
import Promos from './promos/reducer';
import Users from './users/reducer';
import Banners from './banners/reducer';
import AppMessages from './appmessages/reducer';
import Finance from './finance/reducer';
import Companies from './companies/reducer';
import Giftcards from './giftcards/reducer';

export default {
  Auth,
  App,
  Overview,
  Restaurants,
  Collections,
  Bookings,
  Bills,
  Promos,
  Users,
  Banners,
  AppMessages,
  Finance,
  Companies,
  Giftcards
};
