const actions = {
  FINANCE_SUMMARY_REQUEST: 'FINANCE_SUMMARY_REQUEST',
  FINANCE_SUMMARY_SUCCESS: 'FINANCE_SUMMARY_SUCCESS',
  FINANCE_TOPUP_CARDS_REQUEST: 'FINANCE_TOPUP_CARDS_REQUEST',
  FINANCE_TOPUP_CARDS_SUCCESS: 'FINANCE_TOPUP_CARDS_SUCCESS',
  FINANCE_TOPUP_REQUEST: 'FINANCE_TOPUP_REQUEST',
  FINANCE_TOPUP_SUCCESS: 'FINANCE_TOPUP_SUCCESS',
  FINANCE_REPORT_RESTAURANTS_REQUEST: 'FINANCE_REPORT_RESTAURANTS_REQUEST',
  FINANCE_REPORT_RESTAURANTS_SUCCESS: 'FINANCE_REPORT_RESTAURANTS_SUCCESS',
  FINANCE_REPORT_DOWNLOAD_REQUEST: 'FINANCE_REPORT_DOWNLOAD_REQUEST',
  FINANCE_REPORT_DOWNLOAD_SUCCESS: 'FINANCE_REPORT_DOWNLOAD_SUCCESS',
  FINANCE_ADVANCED_REPORT_DOWNLOAD_REQUEST: 'FINANCE_ADVANCED_REPORT_DOWNLOAD_REQUEST',
  FINANCE_ADVANCED_REPORT_DOWNLOAD_SUCCESS: 'FINANCE_ADVANCED_REPORT_DOWNLOAD_SUCCESS',
  FINANCE_INVOICE_REPORT_BILLS_REQUEST: 'FINANCE_INVOICE_REPORT_BILLS_REQUEST',
  FINANCE_INVOICE_REPORT_BILLS_SUCCESS: 'FINANCE_INVOICE_REPORT_BILLS_SUCCESS',
  FINANCE_INVOICE_REPORT_DOWNLOAD_REQUEST: 'FINANCE_INVOICE_REPORT_DOWNLOAD_REQUEST',
  FINANCE_INVOICE_REPORT_DOWNLOAD_SUCCESS: 'FINANCE_INVOICE_REPORT_DOWNLOAD_SUCCESS',
  SMS_REPORT_REQUEST: 'SMS_REPORT_REQUEST',
  SMS_REPORT_SUCCESS: 'SMS_REPORT_SUCCESS',
  SMS_REPORT_ERROR: 'SMS_REPORT_ERROR',
  SMS_REPORT_DOWNLOAD_REQUEST: 'SMS_REPORT_DOWNLOAD_REQUEST',
  SMS_REPORT_DOWNLOAD_SUCCESS: 'SMS_REPORT_DOWNLOAD_SUCCESS',
  SMS_REPORT_DOWNLOAD_ERROR: 'SMS_REPORT_DOWNLOAD_ERROR',
  FINANCE_CLEAR: 'FINANCE_CLEAR',
  FINANCE_ERROR: 'FINANCE_ERROR',
  getSummary: () => ({
    type: actions.FINANCE_SUMMARY_REQUEST
  }),
  getCards: () => ({
    type: actions.FINANCE_TOPUP_CARDS_REQUEST
  }),
  topUp: (data, intl, callback) => ({
    type: actions.FINANCE_TOPUP_REQUEST,
    data,
    intl,
    callback
  }),
  getFinanceReportRestaurants: (startDate, endDate) => ({
    type: actions.FINANCE_REPORT_RESTAURANTS_REQUEST,
    startDate,
    endDate
  }),
  getSMSReport: (startDate, endDate) => ({
    type: actions.SMS_REPORT_REQUEST,
    startDate,
    endDate
  }),
  downloadSMSReport: (startDate, endDate) => ({
    type: actions.SMS_REPORT_DOWNLOAD_REQUEST,
    startDate,
    endDate
  }),
  downloadFinanceReport: (startDate, endDate) => ({
    type: actions.FINANCE_REPORT_DOWNLOAD_REQUEST,
    startDate,
    endDate
  }),
  downloadFinanceAdvancedReport: () => ({
    type: actions.FINANCE_ADVANCED_REPORT_DOWNLOAD_REQUEST,
  }),
  getInvoiceReportBills: (startDate, endDate) => ({
    type: actions.FINANCE_INVOICE_REPORT_BILLS_REQUEST,
    startDate,
    endDate
  }),
  downloadInvoiceReport: (startDate, endDate) => ({
    type: actions.FINANCE_INVOICE_REPORT_DOWNLOAD_REQUEST,
    startDate,
    endDate
  }),
  clearFinance: () => ({
    type: actions.FINANCE_CLEAR
  }),
};
export default actions;
