const actions = {
  GIFTCARDS_REQUEST: 'GIFTCARDS_REQUEST',
  GIFTCARDS_SUCCESS: 'GIFTCARDS_SUCCESS',
  GIFTCARDS_CREATE_REQUEST: 'GIFTCARDS_CREATE_REQUEST',
  GIFTCARDS_CREATE_SUCCESS: 'GIFTCARDS_CREATE_SUCCESS',
  GIFTCARDS_TEMPLATES_REQUEST: 'GIFTCARDS_TEMPLATES_REQUEST',
  GIFTCARDS_TEMPLATES_SUCCESS: 'GIFTCARDS_TEMPLATES_SUCCESS',
  GIFTCARDS_DOWNLOAD_REQUEST: 'GIFTCARDS_DOWNLOAD_REQUEST',
  GIFTCARDS_DOWNLOAD_SUCCESS: 'GIFTCARDS_DOWNLOAD_SUCCESS',
  GIFTCARDS_ERROR: 'GIFTCARDS_ERROR',
  GIFTCARDS_CLEAR: 'GIFTCARDS_CLEAR',
  getGiftcards: (start, count) => ({
    type: actions.GIFTCARDS_REQUEST,
    start,
    count
  }),
  createGiftcard: (data, form, intl) => ({
    type: actions.GIFTCARDS_CREATE_REQUEST,
    data,
    form,
    intl
  }),
  getGiftcardTemplates: () => ({
    type: actions.GIFTCARDS_TEMPLATES_REQUEST
  }),
  getGiftcardDownload: (giftcardId, downloadUrl) => ({
    type: actions.GIFTCARDS_DOWNLOAD_REQUEST,
    giftcardId,
    downloadUrl
  }),
  clearGiftcards: () => ({
    type: actions.GIFTCARDS_CLEAR
  }),
};
export default actions;
