const actions = {
  BOOKINGS_REQUEST: 'BOOKINGS_REQUEST',
  BOOKINGS_SUCCESS: 'BOOKINGS_SUCCESS',
  BOOKINGS_ERROR: 'BOOKINGS_ERROR',
  BOOKINGS_CLEAR: 'BOOKINGS_CLEAR',
  getBookings: (start, count, search, startTime, endTime) => ({
    type: actions.BOOKINGS_REQUEST,
    start,
    count,
    search,
    startTime,
    endTime
  }),
  clearBookings: () => ({
    type: actions.BOOKINGS_CLEAR
  }),
};
export default actions;
