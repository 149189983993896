import Enlang from './entries/en-US';
import SvLang from './entries/sv-SE';
import { addLocaleData } from 'react-intl';

const AppLocale = {
  en: Enlang,
  sv: SvLang
};

addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.sv.data);

export default AppLocale;
