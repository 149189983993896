const ACTION_NONE = null;
const ACTION_RESTAURANT = "restaurant";
const ACTION_CHAT = "chat";
const ACTION_BOOKING = "booking";
const ACTION_PROMO = "promo";
const ACTION_USER = "user";

const constants = {
  ACTION_NONE,
  ACTION_RESTAURANT,
  ACTION_CHAT,
  ACTION_BOOKING,
  ACTION_PROMO,
  ACTION_USER
};

export default constants;
