import actions from './actions';

const initState = { 
  banners: [],
  banner: {},
  bannersPreview: {}
};

export default function overviewReducer(state = initState, action) {
  switch (action.type) {

    case actions.BANNERS_REQUEST:
      return { ...state, isLoading: true };

    case actions.BANNERS_SUCCESS:
      return { 
        ...state, 
        isLoading: false,
        banners: action.banners
      };

    case actions.BANNERS_DETAILS_REQUEST:
      return { ...state, isLoading: true };

    case actions.BANNERS_DETAILS_SUCCESS:
      return { 
        ...state, 
        isLoading: false,
        banner: action.banner
      };

    case actions.BANNERS_CREATE_REQUEST:
      return { ...state, isLoading: true };

    case actions.BANNERS_CREATE_SUCCESS:
      return { ...state, isLoading: false, banner: action.banner };

    case actions.BANNERS_UPDATE_REQUEST:
      return { ...state, isLoading: true };

    case actions.BANNERS_UPDATE_SUCCESS:
      return { ...state, isLoading: false, banner: action.banner };

    case actions.BANNERS_PREVIEW_SUCCESS:
      return { ...state, bannersPreview: action.bannersPreview };

    case actions.BANNERS_CLEAR:
      return { ...state, banners: [], banner: {}, bannersPreview: {} };

    case actions.BANNERS_ERROR:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}
