import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../settings';
import { getTokenHeader, showNotificationSavedSuccess, showNotificationSavedFailed, uploadFile } from '../../helpers/utility';

export function* getCollections() {
  yield takeEvery(actions.COLLECTIONS_REQUEST, function*() {
    try {

      const response = yield call(fetch, settings.apiUrl + '/dashboard/collections', {
        headers: getTokenHeader()
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('getCollections error'));
      }

      const collections = yield response.json();
      yield put({
        type: actions.COLLECTIONS_SUCCESS,
        collections
      });
    } 
    catch (e) {
      yield put({ type: actions.COLLECTIONS_ERROR });
      return;
    }
  });
}

export function* getCollectionDetails() {
  yield takeEvery(actions.COLLECTIONS_DETAILS_REQUEST, function*({id}) {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/collections/' + id, {
        headers: getTokenHeader()
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('getCollectionDetails error'));
      }

      const collection = yield response.json();
      yield put({
        type: actions.COLLECTIONS_DETAILS_SUCCESS,
        collection
      });
    } 
    catch (e) {
      yield put({ type: actions.COLLECTIONS_ERROR });
      return;
    }
  });
}

export function* createCollection() {
  yield takeEvery(actions.COLLECTIONS_CREATE_REQUEST, function*({data, imageFile, intl}) {
    try {
      let body = getCollectionRequestBody(data);

      if (imageFile) {
        let uploadMediaResponse = yield call(uploadFile, imageFile);
        body.image_id = uploadMediaResponse.id;
      }

      const response = yield call(fetch, settings.apiUrl + '/dashboard/collections', {
        method: 'POST',
        headers: getTokenHeader(),
        body: JSON.stringify(body)
      });

      if (response.status !== 200) {
        throw Object.assign(new Error('createCollection error'));
      }
  
      const collection = yield response.json();
      yield put({
        type: actions.COLLECTIONS_CREATE_SUCCESS,
        collection
      });

      showNotificationSavedSuccess(intl);
    } 
    catch (e) {
      showNotificationSavedFailed(intl);
      yield put({ type: actions.COLLECTIONS_ERROR });
      return;
    }
  });
}

export function* updateCollection() {
  yield takeEvery(actions.COLLECTIONS_UPDATE_REQUEST, function*({oldData, newData, imageFile, intl}) {
    try {
      let body = getCollectionRequestBody(newData);
      if (imageFile) {
        let uploadMediaResponse = yield call(uploadFile, imageFile);
        body.image_id = uploadMediaResponse.id;
      }
      else {
        body.image_id = oldData.image_id;
      }

      const response = yield call(fetch, settings.apiUrl + '/dashboard/collections/' + oldData.id, {
        method: 'PATCH',
        headers: getTokenHeader(),
        body: JSON.stringify(body)
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('updateCollection error'));
      }
  
      const collection = yield response.json();
      yield put({
        type: actions.COLLECTIONS_UPDATE_SUCCESS,
        collection
      });

      showNotificationSavedSuccess(intl);
    } 
    catch (e) {
      showNotificationSavedFailed(intl);
      yield put({ type: actions.COLLECTIONS_ERROR });
      return;
    }
  });
}

export function* deleteCollection() {
  yield takeEvery(actions.COLLECTIONS_DELETE_REQUEST, function*({id, intl}) {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/collections/' + id, {
        method: 'DELETE',
        headers: getTokenHeader()
      });

      if (response.status !== 204) {
        throw Object.assign(new Error('deleteCollection error'));
      }

      yield put({
        type: actions.COLLECTIONS_DELETE_SUCCESS,
        deletedCollectionId: id
      });
    } 
    catch (e) {
      showNotificationSavedFailed(intl);
      yield put({ type: actions.COLLECTIONS_ERROR });
      return;
    }
  });
}

export function* getLocations() {
  yield takeEvery(actions.LOCATIONS_REQUEST, function*() {
    try {

      const response = yield call(fetch, settings.apiUrl + '/dashboard/locations', {
        headers: getTokenHeader()
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('getLocations error'));
      }

      const locations = yield response.json();
      yield put({
        type: actions.LOCATIONS_SUCCESS,
        locations
      });
    } 
    catch (e) {
      yield put({ type: actions.COLLECTIONS_ERROR });
      return;
    }
  });
}

function getCollectionRequestBody(data) {
  let body = {
    type: data.type,
    names: data.names,
    location_ids: data.locations,
    restaurant_ids: data.restaurants,
    priority: parseInt(data.priority, 10),
    is_active: data.is_active
  }

  return body;
}

export default function* rootSaga() {
  yield all([
    fork(getCollections),
    fork(getCollectionDetails),
    fork(createCollection),
    fork(updateCollection),
    fork(deleteCollection),
    fork(getLocations)
  ]);
}
