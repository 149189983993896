const TYPE_PUSH = "push";
const TYPE_MESSAGE = "message";
const TYPE_MESSAGE_NO_PUSH = "message_no_push";

const ACTION_NONE = null;
const ACTION_RESTAURANT = "restaurant";
const ACTION_CHAT = "chat";
const ACTION_BOOKING = "booking";
const ACTION_PROMO = "promo";
const ACTION_USER = "user";

const constants = {
  TYPE_PUSH,
  TYPE_MESSAGE,
  TYPE_MESSAGE_NO_PUSH,
  ACTION_NONE,
  ACTION_RESTAURANT,
  ACTION_CHAT,
  ACTION_BOOKING,
  ACTION_PROMO,
  ACTION_USER
};

export function isAppMessageType(type) {
  return type === TYPE_MESSAGE || type === TYPE_MESSAGE_NO_PUSH;
}

export default constants;
