import { language } from "../../settings";

import englishLang from "../../image/flag/uk.svg";

const config = {
  defaultLanguage: language,
  options: [
    {
      languageId: "english",
      locale: "en",
      text: "English",
      icon: englishLang
    },
    {
      languageId: "swedish",
      locale: "sv",
      text: "Swedish",
      icon: englishLang
    }
  ]
};

export function getCurrentLanguage() {

  var locale = getCurrentLocale();

  let selectedLanguage = config.options[0];
  config.options.forEach(language => {
    if (language.locale === locale) {
      selectedLanguage = language;
    }
  });
  
  return selectedLanguage;
}

export function getCurrentLocale() {

  var locale = (navigator.language || navigator.userLanguage) || config.defaultLanguage;

  // Some browers says "sv" while others say sv-SE
  if (locale && locale.indexOf('-') > -1) {
    locale = locale.split('-')[0];
  }

  return locale;
}

export default config;
