const options = [
  {
    key: 'overview',
    label: 'sidebar.overview',
    leftIcon: 'ion-ios-time-outline',
    children: [
      {
        key: 'overview-today',
        label: 'sidebar.overview.today',
      },
      {
        key: 'overview-bills',
        label: 'sidebar.overview.bills',
      },
    ],
  },
  {
    key: 'users',
    label: 'sidebar.users',
    leftIcon: 'ion-ios-people',
  },
  {
    key: 'restaurants',
    label: 'sidebar.restaurants',
    leftIcon: 'ion-android-restaurant',
    children: [
      {
        key: 'restaurants',
        label: 'sidebar.restaurants.restaurants',
      },
      {
        key: 'restaurants/collections',
        label: 'sidebar.restaurants.collections',
      },
    ],
  },
  {
    key: 'bookings',
    label: 'sidebar.bookings',
    leftIcon: 'ion-ios-calendar-outline',
    children: [
      {
        key: 'bookings',
        label: 'sidebar.bookings',
      },
      {
        key: 'restaurants/unanswered',
        label: 'sidebar.restaurants.unanswered',
      },
    ],
  },
  {
    key: 'bills',
    label: 'sidebar.bills',
    leftIcon: 'ion-ios-paper',
  },
  {
    key: 'promos',
    label: 'sidebar.promos',
    leftIcon: 'ion-ios-cart',
  },
  {
    key: 'discounts',
    label: 'sidebar.discounts',
    leftIcon: 'ion-ios-star',
  },
  {
    key: 'gift-cards',
    label: 'sidebar.giftcards',
    leftIcon: 'ion-ios-flower'
  },
  {
    key: 'companies',
    label: 'sidebar.companies',
    leftIcon: 'ion-briefcase',
  },
  {
    key: 'marketing',
    label: 'sidebar.marketing',
    leftIcon: 'ion-ios-film',
    children: [
      {
        key: 'marketing/banners',
        label: 'sidebar.marketing.banners',
      },
      {
        key: 'marketing/app-messages',
        label: 'sidebar.marketing.app_messages',
      },
    ],
  },
  {
    key: 'finance',
    label: 'sidebar.finance',
    leftIcon: 'ion-cash',
    children: [
      {
        key: 'finance/treasury',
        label: 'sidebar.finance.treasury',
      },
      {
        key: 'finance/finance-report',
        label: 'sidebar.finance.finance_report',
      },
      {
        key: 'finance/invoice-report',
        label: 'sidebar.finance.invoice_report',
      },
      {
        key: 'sms-report',
        label: 'sidebar.sms-report',
      },
      {
        key: 'finance/charts',
        label: 'sidebar.finance.charts',
      },
    ],
  },
];
export default options;
