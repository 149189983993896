import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import overviewSagas from './overview/saga';
import restaurantsSagas from './restaurants/saga';
import collectionSagas from './collections/saga';
import bookingSagas from './bookings/saga';
import billsSagas from './bills/saga';
import promosSagas from './promos/saga';
import usersSagas from './users/saga';
import bannerSagas from './banners/saga';
import appMessageSagas from './appmessages/saga';
import financeSagas from './finance/saga';
import companySagas from './companies/saga';
import giftcardSagas from './giftcards/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    overviewSagas(),
    restaurantsSagas(),
    collectionSagas(),
    bookingSagas(),
    billsSagas(),
    promosSagas(),
    usersSagas(),
    bannerSagas(),
    appMessageSagas(),
    financeSagas(),
    companySagas(),
    giftcardSagas()
  ]);
}
