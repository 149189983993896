const actions = {
  COMPANIES_REQUEST: 'COMPANIES_REQUEST',
  COMPANIES_SUCCESS: 'COMPANIES_SUCCESS',
  COMPANIES_DETAILS_REQUEST: 'COMPANIES_DETAILS_REQUEST',
  COMPANIES_DETAILS_SUCCESS: 'COMPANIES_DETAILS_SUCCESS',
  COMPANIES_UPDATE_REQUEST: 'COMPANIES_UPDATE_REQUEST',
  COMPANIES_UPDATE_SUCCESS: 'COMPANIES_UPDATE_SUCCESS',
  COMPANIES_CREATE_REQUEST: 'COMPANIES_CREATE_REQUEST',
  COMPANIES_CREATE_SUCCESS: 'COMPANIES_CREATE_SUCCESS',
  COMPANIES_USERS_REQUEST: 'COMPANIES_USERS_REQUEST',
  COMPANIES_USERS_SUCCESS: 'COMPANIES_USERS_SUCCESS',
  COMPANIES_USERS_ADD_REQUEST: 'COMPANIES_USERS_ADD_REQUEST',
  COMPANIES_USERS_ADD_SUCCESS: 'COMPANIES_USERS_ADD_SUCCESS',
  COMPANIES_USERS_REMOVE_REQUEST: 'COMPANIES_USERS_REMOVE_REQUEST',
  COMPANIES_USERS_REMOVE_SUCCESS: 'COMPANIES_USERS_REMOVE_SUCCESS',
  COMPANIES_BILLS_REQUEST: 'COMPANIES_BILLS_REQUEST',
  COMPANIES_BILLS_SUCCESS: 'COMPANIES_BILLS_SUCCESS',
  COMPANIES_ERROR: 'COMPANIES_ERROR',
  COMPANIES_CLEAR: 'COMPANIES_CLEAR',
  getCompanies: (start, count, search) => ({
    type: actions.COMPANIES_REQUEST,
    start,
    count,
    search
  }),
  getCompanyDetails: (id, callback) => ({
    type: actions.COMPANIES_DETAILS_REQUEST,
    id,
    callback
  }),
  updateCompany: (id, data, intl) => ({
    type: actions.COMPANIES_UPDATE_REQUEST,
    id,
    data,
    intl
  }),
  createCompany: (data, form, intl) => ({
    type: actions.COMPANIES_CREATE_REQUEST,
    data,
    form,
    intl
  }),
  getCompanyUsers: (companyId) => ({
    type: actions.COMPANIES_USERS_REQUEST,
    companyId
  }),
  addCompanyUser: (companyId, userId, intl) => ({
    type: actions.COMPANIES_USERS_ADD_REQUEST,
    companyId,
    userId,
    intl
  }),
  getCompanyBills: (companyId, start, count) => ({
    type: actions.COMPANIES_BILLS_REQUEST,
    companyId,
    start,
    count
  }),
  removeCompanyUser: (companyId, userId, intl) => ({
    type: actions.COMPANIES_USERS_REMOVE_REQUEST,
    companyId,
    userId,
    intl
  }),
  clearCompanies: () => ({
    type: actions.COMPANIES_CLEAR
  }),
};
export default actions;
