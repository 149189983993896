const CODE_TYPE_CREDIT = "credit";
const CODE_TYPE_FIXED = "fixed";
const CODE_TYPE_FIXED_PERCENTAGE = "fixed_percentage";

const constants = {
  CODE_TYPE_CREDIT,
  CODE_TYPE_FIXED,
  CODE_TYPE_FIXED_PERCENTAGE
};

export default constants;
