const actions = {
  APP_MESSAGES_REQUEST: 'APP_MESSAGES_REQUEST',
  APP_MESSAGES_SUCCESS: 'APP_MESSAGES_SUCCESS',
  APP_MESSAGES_DETAILS_REQUEST: 'APP_MESSAGES_DETAILS_REQUEST',
  APP_MESSAGES_DETAILS_SUCCESS: 'APP_MESSAGES_DETAILS_SUCCESS',
  APP_MESSAGES_CREATE_REQUEST: 'APP_MESSAGES_CREATE_REQUEST',
  APP_MESSAGES_CREATE_SUCCESS: 'APP_MESSAGES_CREATE_SUCCESS',
  APP_MESSAGES_UPDATE_REQUEST: 'APP_MESSAGES_UPDATE_REQUEST',
  APP_MESSAGES_UPDATE_SUCCESS: 'APP_MESSAGES_UPDATE_SUCCESS',
  APP_MESSAGES_DELETE_REQUEST: 'APP_MESSAGES_DELETE_REQUEST',
  APP_MESSAGES_DELETE_SUCCESS: 'APP_MESSAGES_DELETE_SUCCESS',
  APP_MESSAGES_FILTER_REQUEST: 'APP_MESSAGES_FILTER_REQUEST',
  APP_MESSAGES_FILTER_SUCCESS: 'APP_MESSAGES_FILTER_SUCCESS',
  APP_MESSAGES_ERROR: 'APP_MESSAGES_ERROR',
  APP_MESSAGES_CLEAR: 'APP_MESSAGES_CLEAR',
  getAppMessages: (start, count, search) => ({
    type: actions.APP_MESSAGES_REQUEST,
    start,
    count,
    search
  }),
  getAppMessageDetails: (id) => ({
    type: actions.APP_MESSAGES_DETAILS_REQUEST,
    id
  }),
  createAppMessage: (data, imageFile, intl) => ({
    type: actions.APP_MESSAGES_CREATE_REQUEST,
    data,
    imageFile,
    intl
  }),
  updateAppMessage: (oldData, newData, imageFile, intl) => ({
    type: actions.APP_MESSAGES_UPDATE_REQUEST,
    oldData,
    newData,
    imageFile, 
    intl
  }),
  deleteAppMessage: (id, intl) => ({
    type: actions.APP_MESSAGES_DELETE_REQUEST,
    id,
    intl
  }),
  getAppMessageFilters: () => ({
    type: actions.APP_MESSAGES_FILTER_REQUEST
  }),
  clearAppMessages: () => ({
    type: actions.APP_MESSAGES_CLEAR
  }),
};
export default actions;
