import { Map } from 'immutable';
import Notification from '../components/notification';
import { call } from 'redux-saga/effects';
import settings from '../settings';

export function clearToken() {
  localStorage.removeItem('access_token');
}

export function getToken() {
  try {
    const idToken = localStorage.getItem('access_token');
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function setToken(token) {
  localStorage.setItem('access_token', token);
}

export function getTokenHeader() {
  return {
    'Authorization': 'Bearer ' + getToken().get('idToken'),
    'Content-Type': 'application/json'
  }
}

export function getMultipartHeader() {
  return {
    'Authorization': 'Bearer ' + getToken().get('idToken')
  }
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = number => {
    return number > 1 ? 's' : '';
  };
  const number = num => (num > 9 ? '' + num : '0' + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + ' day' + numberEnding(days);
      } else {
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}

export function formatNumber(number) {
  if (!number) return number;
  return number.toLocaleString('sv')    
}

export function isCurrency(currency) {
  if (!currency) return false;
  return currency === 'SEK';
}

/**
 * Simple parseFloat but also works with ',' as decimal point
 */
export function parseFloatLocale(value) {
  if (!value) return value;
  value = value.toString().replace(',', '.');
  return parseFloat(value);
}

export function showNotificationSavedSuccess(intl) {
  Notification(
    'success',
    intl.formatMessage({id: 'notification.save_success'})
  );
}

export function showNotificationSavedFailed(intl, messageId) {
  Notification(
    'error',
    intl.formatMessage({id: messageId || 'notification.error'})
  );
}

export function showNotificationSuccess(intl, messageId, values) {
  if (!values) {
    Notification('success', intl.formatMessage({id: messageId}));
  }
  else {
    Notification('success', intl.formatMessage({id: messageId}, {...values}));
  }
}

export function showNotificationFailed(intl, messageId, values) {
  if (!values) {
    Notification('error', intl.formatMessage({id: messageId}));
  }
  else {
    Notification('error', intl.formatMessage({id: messageId}, {...values}));
  }
}

export function showNotificationError(message) {
  Notification('error', message);
}

export function* uploadFile(file) {
  try {
    var formData = new FormData();
    formData.append("file", file);
    formData.append("file_name", file.name);
    formData.append("mime_type", file.type);

    const uploadResponse = yield call(fetch, settings.apiUrl + '/dashboard/media', {
      method: 'POST',
      headers: getMultipartHeader(),
      body: formData
    });

    if (uploadResponse.status !== 201) {
      throw Object.assign(new Error('uploadMedia error'));
    }

    let responseJson = yield uploadResponse.json();
    return responseJson;
  } 
  catch (e) {
    throw e;
  }
}

/*
  To use with Select.filterOption which is used as callback for searching select options
*/
export function filterSelectOptions(input, option) {
  return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}