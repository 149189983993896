import actions from './actions';

const initState = { 
  appMessages: [],
  appMessage: {},
  appMessageFilters: [],
  hasMoreAppMessages: true
};

export default function overviewReducer(state = initState, action) {
  switch (action.type) {

    case actions.APP_MESSAGES_REQUEST:
      var requestState = {
        ...state,
        hasMoreAppMessages: true,
        isLoading: true
      };

      // If new filter or other search, clean data
      if (state.search !== action.search) {
        requestState.appMessages = [];
      }

      return requestState;

    case actions.APP_MESSAGES_SUCCESS:
      return {
        ...state,
        hasMoreAppMessages: action.appMessages.length > 0,
        isLoading: false,
        appMessages:
          state.appMessages && action.start > 0
            ? state.appMessages.concat(action.appMessages)
            : action.appMessages,
        search: action.search
      };

    case actions.APP_MESSAGES_DETAILS_REQUEST:
      return { ...state, isLoading: true };

    case actions.APP_MESSAGES_DETAILS_SUCCESS:
      return { 
        ...state, 
        isLoading: false,
        appMessage: action.appMessage
      };

    case actions.APP_MESSAGES_CREATE_REQUEST:
      return { ...state, isLoading: true };

    case actions.APP_MESSAGES_CREATE_SUCCESS:
      return { ...state, isLoading: false, appMessage: action.appMessage };

    case actions.APP_MESSAGES_UPDATE_REQUEST:
      return { ...state, isLoading: true };

    case actions.APP_MESSAGES_UPDATE_SUCCESS:
      return { ...state, isLoading: false, appMessage: action.appMessage };

    case actions.APP_MESSAGES_DELETE_SUCCESS:
        var appMessages = state.appMessages;
        var deletedIndex = appMessages.findIndex(x => x.id === action.deletedAppMessageId);
        if (deletedIndex > -1) {
          appMessages.splice(deletedIndex, 1);
        }
  
        return {
          ...state,
          appMessages,
          isLoading: false,
          deletedAppMessageId: action.deletedAppMessageId
        };

    case actions.APP_MESSAGES_FILTER_REQUEST:
      return { ...state, isLoading: true };

    case actions.APP_MESSAGES_FILTER_SUCCESS:
      return { ...state, isLoading: false, appMessageFilters: action.appMessageFilters };

    case actions.APP_MESSAGES_CLEAR:
      return { ...state, appMessages: [], appMessage: {}, appMessageFilters: [] };

    case actions.APP_MESSAGES_ERROR:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}
