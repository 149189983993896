const actions = {
  CARDS_REQUEST: 'CARDS_REQUEST',
  CARDS_SUCCESS: 'CARDS_SUCCESS',
  CHART_TOP_RESTAURANTS_CLOSED_BILLS_REQUEST: 'CHART_TOP_RESTAURANTS_CLOSED_BILLS_REQUEST',
  CHART_TOP_RESTAURANTS_CLOSED_BILLS_SUCCESS: 'CHART_TOP_RESTAURANTS_CLOSED_BILLS_SUCCESS',
  CHART_CLOSED_BILLS_REQUEST: 'CHART_CLOSED_BILLS_REQUEST',
  CHART_CLOSED_BILLS_SUCCESS: 'CHART_CLOSED_BILLS_SUCCESS',
  CHART_BILLS_WITH_CREDITS_REQUEST: 'CHART_BILLS_WITH_CREDITS_REQUEST',
  CHART_BILLS_WITH_CREDITS_SUCCESS: 'CHART_BILLS_WITH_CREDITS_SUCCESS',
  CHART_USED_PAYMENT_METHODS_REQUEST: 'CHART_USED_PAYMENT_METHODS_REQUEST',
  CHART_USED_PAYMENT_METHODS_SUCCESS: 'CHART_USED_PAYMENT_METHODS_SUCCESS',
  CHART_TURNOVER_PAYMENT_METHODS_REQUEST: 'CHART_TURNOVER_PAYMENT_METHODS_REQUEST',
  CHART_TURNOVER_PAYMENT_METHODS_SUCCESS: 'CHART_TURNOVER_PAYMENT_METHODS_SUCCESS',
  CHART_CREDIT_TURNOVER_REQUEST: 'CHART_CREDIT_TURNOVER_REQUEST',
  CHART_CREDIT_TURNOVER_SUCCESS: 'CHART_CREDIT_TURNOVER_SUCCESS',
  CHART_NEW_USERS_REQUEST: 'CHART_NEW_USERS_REQUEST',
  CHART_NEW_USERS_SUCCESS: 'CHART_NEW_USERS_SUCCESS',
  CHART_BOOKINGS_REQUEST: 'CHART_BOOKINGS_REQUEST',
  CHART_BOOKINGS_SUCCESS: 'CHART_BOOKINGS_SUCCESS',
  CHART_TURNOVER_AND_REVENUE_REQUEST: 'CHART_TURNOVER_AND_REVENUE_REQUEST',
  CHART_TURNOVER_AND_REVENUE_SUCCESS: 'CHART_TURNOVER_AND_REVENUE_SUCCESS',
  OVERVIEW_CLEAR: 'OVERVIEW_CLEAR',
  OVERVIEW_ERROR: 'OVERVIEW_ERROR',
  getCards: () => ({
    type: actions.CARDS_REQUEST
  }),
  getTopRestaurantsClosedBillsChart: (perspective, start, end) => ({
    type: actions.CHART_TOP_RESTAURANTS_CLOSED_BILLS_REQUEST,
    perspective,
    start,
    end
  }),
  getClosedBillsChart: (perspective, start, end) => ({
    type: actions.CHART_CLOSED_BILLS_REQUEST,
    perspective,
    start,
    end
  }),
  getBillsWithCreditsChart: (perspective, start, end) => ({
    type: actions.CHART_BILLS_WITH_CREDITS_REQUEST,
    perspective,
    start,
    end
  }),
  getUsedPaymentMethodsChart: (perspective, start, end) => ({
    type: actions.CHART_USED_PAYMENT_METHODS_REQUEST,
    perspective,
    start,
    end
  }),
  getTurnoverPaymentMethodsChart: (perspective, start, end) => ({
    type: actions.CHART_TURNOVER_PAYMENT_METHODS_REQUEST,
    perspective,
    start,
    end
  }),
  getCreditTurnoverChart: (perspective, start, end) => ({
    type: actions.CHART_CREDIT_TURNOVER_REQUEST,
    perspective,
    start,
    end
  }),
  getNewUsersChart: (perspective, start, end) => ({
    type: actions.CHART_NEW_USERS_REQUEST,
    perspective,
    start,
    end
  }),
  getBookingsChart: (perspective, start, end) => ({
    type: actions.CHART_BOOKINGS_REQUEST,
    perspective,
    start,
    end
  }),
  getTurnoverAndRevenueChart: (perspective, start, end) => ({
    type: actions.CHART_TURNOVER_AND_REVENUE_REQUEST,
    perspective,
    start,
    end
  }),
  clearOverview: () => ({
    type: actions.OVERVIEW_CLEAR
  })
};
export default actions;
