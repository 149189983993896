import actions from './actions';
import billActions from "../bills/actions";

const initState = { 
  companies: [],
  company: {},
  hasMoreCompanies: true
};

export default function overviewReducer(state = initState, action) {
  switch (action.type) {

    case actions.COMPANIES_REQUEST:
        var requestState = {
          ...state,
          hasMoreCompanies: true,
          isLoading: true
        };
  
        // If new search, clean data
        if (state.search !== action.search) {
          requestState.companies = [];
        }
  
        return requestState;

    case actions.COMPANIES_SUCCESS:
        return {
          ...state,
          hasMoreCompanies: action.companies.length > 0,
          isLoading: false,
          companies:
            state.companies && action.start > 0
              ? state.companies.concat(action.companies)
              : action.companies,
          search: action.search
        };

    case actions.COMPANIES_DETAILS_REQUEST:
      return { ...state, isLoading: true };

    case actions.COMPANIES_DETAILS_SUCCESS:
      return { ...state, isLoading: false, company: action.company };

    case actions.COMPANIES_UPDATE_REQUEST:
      return { ...state, isLoading: true };

    case actions.COMPANIES_UPDATE_SUCCESS:
      return { ...state, isLoading: false, company: action.company };

    case actions.COMPANIES_CREATE_REQUEST:
      return { ...state, isLoading: true };

    case actions.COMPANIES_CREATE_SUCCESS:
      return { ...state, isLoading: false, company: action.company };

    case actions.COMPANIES_USERS_REQUEST:
      return { ...state, isCompanyUsersLoading: true};

    case actions.COMPANIES_USERS_SUCCESS:
      if (state.company) {
        return { 
          ...state,
          isCompanyUsersLoading: false,
          company: {
            ...state.company,
            userList: action.users
          }
        };
      }
      return { ...state, isLoading: false };

    case actions.COMPANIES_BILLS_REQUEST:
      return { ...state, isCompanyBillsLoading: true, hasMoreCompanyBills: true };

    case actions.COMPANIES_BILLS_SUCCESS:
      if (state.company) {
        return { 
          ...state,
          isCompanyBillsLoading: false,
          hasMoreCompanyBills: action.bills.length > 0,
          company: {
            ...state.company,
            billList: state.company.billList && action.start > 0
              ? state.company.billList.concat(action.bills)
              : action.bills,
          }
        };
      }
      return { ...state, isLoading: false };

    case billActions.BILL_DELETE_SUCCESS:
      if (state.company && state.company.billList) {
        var bills = state.company.billList;
        var deletedIndex = bills.findIndex(x => x.id === action.deletedBillId);
        if (deletedIndex > -1) {
          bills.splice(deletedIndex, 1);
        }
        return { ...state, company: { ...state.company, billList: bills } };
      }
      return { ...state, company: { ...state.company } };

    case actions.COMPANIES_ERROR:
      return { ...state, isLoading: false };

    case actions.COMPANIES_CLEAR:
      return { ...state, companies: [], company: {} };

    default:
      return state;
  }
}
