const actions = {
  COLLECTIONS_REQUEST: 'COLLECTIONS_REQUEST',
  COLLECTIONS_SUCCESS: 'COLLECTIONS_SUCCESS',
  COLLECTIONS_DETAILS_REQUEST: 'COLLECTIONS_DETAILS_REQUEST',
  COLLECTIONS_DETAILS_SUCCESS: 'COLLECTIONS_DETAILS_SUCCESS',
  COLLECTIONS_CREATE_REQUEST: 'COLLECTIONS_CREATE_REQUEST',
  COLLECTIONS_CREATE_SUCCESS: 'COLLECTIONS_CREATE_SUCCESS',
  COLLECTIONS_UPDATE_REQUEST: 'COLLECTIONS_UPDATE_REQUEST',
  COLLECTIONS_UPDATE_SUCCESS: 'COLLECTIONS_UPDATE_SUCCESS',
  COLLECTIONS_DELETE_REQUEST: 'COLLECTIONS_DELETE_REQUEST',
  COLLECTIONS_DELETE_SUCCESS: 'COLLECTIONS_DELETE_SUCCESS',
  LOCATIONS_REQUEST: 'LOCATIONS_REQUEST',
  LOCATIONS_SUCCESS: 'LOCATIONS_SUCCESS',
  COLLECTIONS_ERROR: 'COLLECTIONS_ERROR',
  COLLECTIONS_CLEAR: 'COLLECTIONS_CLEAR',
  getCollections: () => ({
    type: actions.COLLECTIONS_REQUEST
  }),
  getCollectionDetails: (id) => ({
    type: actions.COLLECTIONS_DETAILS_REQUEST,
    id
  }),
  createCollection: (data, imageFile, intl) => ({
    type: actions.COLLECTIONS_CREATE_REQUEST,
    data,
    imageFile,
    intl
  }),
  updateCollection: (oldData, newData, imageFile, intl) => ({
    type: actions.COLLECTIONS_UPDATE_REQUEST,
    oldData,
    newData,
    imageFile, 
    intl
  }),
  deleteCollection: (id, intl) => ({
    type: actions.COLLECTIONS_DELETE_REQUEST,
    id,
    intl
  }),
  getLocations: () => ({
    type: actions.LOCATIONS_REQUEST
  }),
  clearCollections: () => ({
    type: actions.COLLECTIONS_CLEAR
  }),
};
export default actions;
