import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../settings';
import { getTokenHeader, showNotificationSavedSuccess, showNotificationSavedFailed } from '../../helpers/utility';

export function* getUsers() {
  yield takeEvery(actions.USERS_REQUEST, function*({start, count, filter, search}) {
    try {

      let url = settings.apiUrl + '/dashboard/users?' +
      'start=' + start +
      '&count=' + count +
      '&filter=' + (filter || 'all');
      if (search) {
        url += '&q=' + encodeURIComponent(search);
      }

      const response = yield call(fetch, url, {
        headers: getTokenHeader()
      });

      if (response.status !== 200) {
        yield put({ type: actions.USERS_ERROR });
        return;
      }

      const responseBody = yield response.json();
      yield put({
        type: actions.USERS_SUCCESS,
        users: responseBody,
        filter: filter,
        search: search,
        start
      });

    }
    catch (e) {
        yield put({ type: actions.USERS_ERROR });
        return;
    }
  });
}

export function* getUserDetails(id) {
  try {
    const response = yield call(fetch, settings.apiUrl + '/dashboard/users/' + id, {
      headers: getTokenHeader()
    });

    if (response.status !== 200) {
      yield put({ type: actions.USERS_ERROR });
      return;
    }

    const user = yield response.json();

    var creditsAndDiscounts = [];
    if (user.credits) {
      user.credits.forEach(credit => {
        creditsAndDiscounts.push({
          id: credit.id,
          type: credit.type,
          promoId: credit.promo_id,
          restaurants: credit.restaurants,
          code: credit.code,
          active: credit.is_active,
          activatedAt: credit.activated_at,
          expiresAt: credit.expires_at,
          amount: credit.amount,
          objectType: 'credit',
          source: credit.source,
          giverName: credit.giver_name,
          note: credit.note
        });
      })
    }

    if (user.discounts) {
      user.discounts.forEach(discount => {
        var discountToAdd = {
          type: discount.type,
          promoId: discount.promo_id,
          restaurants: discount.restaurants,
          code: discount.code,
          active: discount.is_active,
          activatedAt: discount.activated_at,
          expiresAt: discount.expires_at,
          objectType: 'discount'
        };

        if (discount.type === 1) {
          discountToAdd.value = 0.12;
        }
        else if (discount.type === 2) {
          discountToAdd.value = 0.3;
        }
        else if (discount.type === 3) {
          discountToAdd.value = 0.3;
        }

        creditsAndDiscounts.push(discountToAdd);
      })
    }

    user.deductions = creditsAndDiscounts;
    return user;
  }
  catch (e) {
      yield put({ type: actions.USERS_ERROR });
      return;
  }
}

function* watchUserDetails() {
  yield takeEvery(actions.USERS_DETAILS_REQUEST, function*({ id, callback }) {
    var user = yield call(getUserDetails, id);
    if (callback) {
      callback();
    }
    yield put({
      type: actions.USERS_DETAILS_SUCCESS,
      user
    });
  });
}

export function* updateUser() {
  yield takeEvery(actions.USERS_UPDATE_REQUEST, function*({id, data, intl}) {
    try {

      var user = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        country_code: data.phone ? data.phone.substring(0, 3) : null,
        phone_number: data.phone ? data.phone.substring(3) : null,
        push_notifications: data.push_notifications,
        offer_notifications: data.offer_notifications
      }

      const response = yield call(fetch, settings.apiUrl + '/dashboard/users/' + id, {
        method: 'PATCH',
        headers: getTokenHeader(),
        body: JSON.stringify(user)
      });

      if (response.status !== 202) {
        throw Object.assign(new Error('updateUser error'));
      }

      const responseUser = yield response.json();
      yield put({
        type: actions.USERS_UPDATE_SUCCESS,
        user: responseUser
      });

      showNotificationSavedSuccess(intl);
    }
    catch (e) {
      showNotificationSavedFailed(intl);
      yield put({ type: actions.USERS_ERROR });
      return;
    }
  });
}

export function* deleteUser() {
  yield takeEvery(actions.USERS_DELETE_REQUEST, function*({id, intl}) {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/users/' + id, {
        method: 'DELETE',
        headers: getTokenHeader()
      });

      if (response.status !== 200 && response.status !== 204) {
        throw Object.assign(new Error('deleteUser error'));
      }

      // Fetch new user details
      var user = yield call(getUserDetails, id);
      yield put({
        type: actions.USERS_DELETE_SUCCESS,
        user
      });

      showNotificationSavedSuccess(intl);
    }
    catch (e) {
      showNotificationSavedFailed(intl);
      yield put({ type: actions.USERS_ERROR });
      return;
    }
  });
}

export function* getLoyaltyLevels() {
  yield takeEvery(actions.USERS_LOYALTY_LEVELS_REQUEST, function*() {
    try {

      const response = yield call(fetch, settings.apiUrl + '/dashboard/loyalty/levels', {
        headers: getTokenHeader()
      });

      if (response.status !== 200) {
        yield put({ type: actions.USERS_ERROR });
        return;
      }

      const loyaltyLevels = yield response.json();
      yield put({
        type: actions.USERS_LOYALTY_LEVELS_SUCCESS,
        loyaltyLevels
      });

    }
    catch (e) {
        yield put({ type: actions.USERS_ERROR });
        return;
    }
  });
}

export function* updateLoyalty() {
  yield takeEvery(actions.USERS_UPDATE_LOYALTY_REQUEST, function*({id, data, intl}) {
    try {

      var body = {
        level: data.level,
        is_staff: data.isStaff,
      }

      // If empty, give no credits.
      // If 0, default credits for the level will be given. Otherwise the amount of credits will be given
      if (!data.credits) {
        body.grant_credits = false;
      }
      else {
        body.grant_credits = true;
        body.credits = parseInt(data.credits, 10) * 100;
      }

      const response = yield call(fetch, settings.apiUrl + '/dashboard/users/' + id + '/loyalty', {
        method: 'PATCH',
        headers: getTokenHeader(),
        body: JSON.stringify(body)
      });

      if (response.status !== 200) {
        throw Object.assign(new Error('updateLoyalty error'));
      }

      // Fetch new user details
      var user = yield call(getUserDetails, id);
      yield put({
        type: actions.USERS_UPDATE_LOYALTY_SUCCESS,
        user
      });

      showNotificationSavedSuccess(intl);
    }
    catch (e) {
      showNotificationSavedFailed(intl);
      yield put({ type: actions.USERS_ERROR });
      return;
    }
  });
}

export function* addCreditsToUser() {
  yield takeEvery(actions.USERS_ADD_CREDITS_REQUEST, function*({id, credits, note, intl}) {
    try {
      var data = {
        credits: parseInt(credits, 10) * 100,
        note
      }

      const response = yield call(fetch, settings.apiUrl + '/dashboard/users/' + id + '/credits', {
        method: 'POST',
        headers: getTokenHeader(),
        body: JSON.stringify(data)
      });

      if (response.status !== 200) {
        throw Object.assign(new Error('addCreditsToUser error'));
      }

      // Fetch new user details
      var user = yield call(getUserDetails, id);
      yield put({
        type: actions.USERS_ADD_CREDITS_SUCCESS,
        user
      });

      showNotificationSavedSuccess(intl);
    }
    catch (e) {
      showNotificationSavedFailed(intl);
      yield put({ type: actions.USERS_ERROR });
      return;
    }
  });
}

export function* updateUserCredit() {
  yield takeEvery(actions.USERS_UPDATE_CREDITS_REQUEST, function*({userId, creditId, data, intl}) {
    try {

      data.amount = parseInt(data.amount, 10) * 100
      const response = yield call(fetch, settings.apiUrl + '/dashboard/credits/' + creditId, {
        method: 'PATCH',
        headers: getTokenHeader(),
        body: JSON.stringify(data)
      });

      if (response.status !== 200) {
        throw Object.assign(new Error('updateUserCredit error'));
      }

      // Fetch new user details
      var user = yield call(getUserDetails, userId);
      yield put({
        type: actions.USERS_UPDATE_CREDITS_SUCCESS,
        user
      });

      showNotificationSavedSuccess(intl);
    }
    catch (e) {
      showNotificationSavedFailed(intl);
      yield put({ type: actions.USERS_ERROR });
      return;
    }
  });
}

export function* getUserBookings() {
  yield takeEvery(actions.USERS_BOOKINGS_REQUEST, function*({userId, start, count}) {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/users/' + userId + '/bookings?start=' + start + '&count=' + count, {
        headers: getTokenHeader()
      });

      if (response.status !== 200) {
        throw Object.assign(new Error('getUserBookings error'));
      }

      const bookings = yield response.json();
      yield put({
        type: actions.USERS_BOOKINGS_SUCCESS,
        bookings,
        start
      });
    }
    catch (e) {
      yield put({ type: actions.USERS_ERROR });
      return;
    }
  });
}

export function* getUserBills() {
  yield takeEvery(actions.USERS_BILLS_REQUEST, function*({userId, start, count}) {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/users/' + userId + '/bills?start=' + start + '&count=' + count, {
        headers: getTokenHeader()
      });

      if (response.status !== 200) {
        throw Object.assign(new Error('getUserBills error'));
      }

      const bills = yield response.json();
      yield put({
        type: actions.USERS_BILLS_SUCCESS,
        bills,
        start
      });
    }
    catch (e) {
      yield put({ type: actions.USERS_ERROR });
      return;
    }
  });
}

export function* getUserPaymentOptions() {
  yield takeEvery(actions.USERS_PAYMENT_OPTIONS_REQUEST, function*({ userId }) {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/users/' + userId + '/payment-options', {
        headers: getTokenHeader()
      });

      if (response.status !== 200) {
        throw Object.assign(new Error('getUserPaymentOptions error'));
      }

      const paymentOptions = yield response.json();
      yield put({
        type: actions.USERS_PAYMENT_OPTIONS_SUCCESS,
        paymentOptions
      });
    }
    catch (e) {
      yield put({ type: actions.USERS_ERROR });
      return;
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getUsers),
    fork(watchUserDetails),
    fork(updateUser),
    fork(deleteUser),
    fork(getLoyaltyLevels),
    fork(updateLoyalty),
    fork(addCreditsToUser),
    fork(updateUserCredit),
    fork(getUserBookings),
    fork(getUserBills),
    fork(getUserPaymentOptions)
  ]);
}
