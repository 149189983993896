import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../settings';
import bannerConstants from '../../containers/Banners/constants';
import { getTokenHeader, showNotificationSavedSuccess, showNotificationSavedFailed, uploadFile } from '../../helpers/utility';

export function* getBanners() {
  try {
    const response = yield call(fetch, settings.apiUrl + '/dashboard/banners', {
      headers: getTokenHeader()
    });

    if (response.status !== 200) {
      throw Object.assign(new Error('getBanners error'));
    }

    const responseBody = yield response.json();
    yield put({
      type: actions.BANNERS_SUCCESS,
      banners: responseBody
    });
  } 
  catch (e) {
    yield put({ type: actions.BANNERS_ERROR });
    return;
  }
}

function* watchGetBanners() {
  yield takeEvery(actions.BANNERS_REQUEST, function*() {
    yield call(getBanners);
  });
}

export function* getBannerDetails() {
  yield takeEvery(actions.BANNERS_DETAILS_REQUEST, function*({id}) {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/banners/' + id, {
        headers: getTokenHeader()
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('getBannerDetails error'));
      }

      const banner = yield response.json();
      setBannerActionVariables(banner);

      yield put({
        type: actions.BANNERS_DETAILS_SUCCESS,
        banner
      });
    } 
    catch (e) {
      yield put({ type: actions.BANNERS_ERROR });
      return;
    }
  });
}

export function* createBanner() {
  yield takeEvery(actions.BANNERS_CREATE_REQUEST, function*({data, imageFile, intl}) {
    try {
      let body = getBannerRequestBody(data);
      if (imageFile) {
        let uploadMediaResponse = yield call(uploadFile, imageFile);
        body.image_id = uploadMediaResponse.id;
      }

      const response = yield call(fetch, settings.apiUrl + '/dashboard/banners', {
        method: 'POST',
        headers: getTokenHeader(),
        body: JSON.stringify(body)
      });

      if (response.status !== 200) {
        throw Object.assign(new Error('createBanner error'));
      }
  
      const responseBody = yield response.json();
      yield put({
        type: actions.BANNERS_CREATE_SUCCESS,
        banner: responseBody
      });

      showNotificationSavedSuccess(intl);
    } 
    catch (e) {
      showNotificationSavedFailed(intl);
      yield put({ type: actions.BANNERS_ERROR });
      return;
    }
  });
}

export function* updateBanner() {
  yield takeEvery(actions.BANNERS_UPDATE_REQUEST, function*({oldData, newData, imageFile, intl}) {
    try {
      let body = getBannerRequestBody(newData);
      if (imageFile) {
        let uploadMediaResponse = yield call(uploadFile, imageFile);
        body.image_id = uploadMediaResponse.id;
      }
      else {
        body.image_id = oldData.image_id;
      }

      const response = yield call(fetch, settings.apiUrl + '/dashboard/banners/' + oldData.id, {
        method: 'PATCH',
        headers: getTokenHeader(),
        body: JSON.stringify(body)
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('updateBanner error'));
      }
  
      const banner = yield response.json();
      setBannerActionVariables(banner);

      yield put({
        type: actions.BANNERS_UPDATE_SUCCESS,
        banner
      });

      showNotificationSavedSuccess(intl);
    } 
    catch (e) {
      showNotificationSavedFailed(intl);
      yield put({ type: actions.BANNERS_ERROR });
      return;
    }
  });
}

export function* deleteBanner() {
  yield takeEvery(actions.BANNERS_DELETE_REQUEST, function*({id, intl}) {
    try {
      const response = yield call(fetch, settings.apiUrl + '/dashboard/banners/' + id, {
        method: 'DELETE',
        headers: getTokenHeader()
      });

      if (response.status !== 204) {
        throw Object.assign(new Error('deleteBanner error'));
      }

      showNotificationSavedSuccess(intl);
      yield put({ type: actions.BANNERS_REQUEST });

      // Update preview
      yield put({ type: actions.BANNERS_PREVIEW_REQUEST });
    } 
    catch (e) {
      showNotificationSavedFailed(intl);
      yield put({ type: actions.BANNERS_ERROR });
      return;
    }
  });
}

export function* getBannersPreview() {
  yield takeEvery(actions.BANNERS_PREVIEW_REQUEST, function*() {
    try {
      const response = yield call(fetch, settings.apiUrl + '/user/banners', {
        headers: getTokenHeader()
      });
  
      if (response.status !== 200) {
        throw Object.assign(new Error('getBannersPreview error'));
      }

      const banners = yield response.json();

      yield put({
        type: actions.BANNERS_PREVIEW_SUCCESS,
        bannersPreview: banners
      });
    } 
    catch (e) {
      yield put({ type: actions.BANNERS_ERROR });
      return;
    }
  });
}

function getBannerRequestBody(data) {
  let body = {
    titles: data.titles,
    descriptions_short: data.descriptions_short,
    descriptions: data.descriptions,
    date_start: data.starts.format(),
    date_end: data.ends.format(),
    priority: parseInt(data.priority, 10),
    htmls: data.htmls || null,
    action: data.action
  }

  if (body.action) {
    if (data.action_restaurant) {
      body.action += '/' + data.action_restaurant;
    }
    else if (data.action_promo) {
      body.action += '/' + data.action_promo;
    }
  }

  return body;
}

/**
 * Splits banner action variables to separate ones
 */
function setBannerActionVariables(banner) {
  if (banner.action) {
    let actionValues = banner.action.split('/');
    if (actionValues.length > 0) {
      banner.action = actionValues[0];
      if (actionValues.length > 1) {
        if (banner.action === bannerConstants.ACTION_RESTAURANT 
          || banner.action === bannerConstants.ACTION_CHAT 
          || banner.action === bannerConstants.ACTION_BOOKING) {
            banner.action_restaurant = actionValues[1];
        }
        else if (banner.action === bannerConstants.ACTION_PROMO) {
          banner.action_promo = actionValues[1];
        }
      }
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetBanners),
    fork(getBannerDetails),
    fork(createBanner),
    fork(updateBanner),
    fork(deleteBanner),
    fork(getBannersPreview)
  ]);
}
