import actions from './actions';

const initState = { 
  collections: [],
  collection: {},
  locations: []
};

export default function overviewReducer(state = initState, action) {
  switch (action.type) {

    case actions.COLLECTIONS_REQUEST:
      return { ...state, isLoading: true };

    case actions.COLLECTIONS_SUCCESS:
      return { 
        ...state, 
        isLoading: false,
        collections: action.collections
      };

    case actions.COLLECTIONS_DETAILS_REQUEST:
      return { ...state, isLoading: true };

    case actions.COLLECTIONS_DETAILS_SUCCESS:
      return { 
        ...state, 
        isLoading: false,
        collection: action.collection
      };

    case actions.COLLECTIONS_CREATE_REQUEST:
      return { ...state, isLoading: true };

    case actions.COLLECTIONS_CREATE_SUCCESS:
      return { ...state, isLoading: false, collection: action.collection };

    case actions.COLLECTIONS_UPDATE_REQUEST:
      return { ...state, isLoading: true };

    case actions.COLLECTIONS_UPDATE_SUCCESS:
      return { ...state, isLoading: false, collection: action.collection };

    case actions.COLLECTIONS_DELETE_SUCCESS:
        var collections = state.collections;
        var deletedIndex = collections.findIndex(x => x.id === action.deletedCollectionId);
        if (deletedIndex > -1) {
          collections.splice(deletedIndex, 1);
        }
  
        return {
          ...state,
          collections,
          isLoading: false,
          deletedCollectionId: action.deletedCollectionId
        };

    case actions.LOCATIONS_SUCCESS:
      return {  ...state, locations: action.locations };

    case actions.COLLECTIONS_CLEAR:
      return { ...state, collections: [], collection: {} };

    case actions.COLLECTIONS_ERROR:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}
